import { getHost } from "../utils";
import Storage from "src/utils/storage/Storage";

export class Service {
  constructor() {
    this.baseUrl = getHost();
  }

  getAuthHeader() {
    const token = Storage.get("token");
    return { "Authorization": `Bearer ${token}` };
  }

  getAcceptTypeJsonHeader() {
    return { "Accept": "application/json" };
  }

  setContentTypeJsonHeader() {
    return { "Content-Type": "application/json" };
  }

  postNoContentTypeJsonHeader() {
    return { "Content-Type": false };
  }

  setSkipTutorHeader() {
    return { "Skip-Tutor": true };
  }
}
