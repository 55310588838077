export const TOOLBELT_TOOL_TAGS = "TOOLBELT_TOOL_TAGS";
export const TOOLBELT_TOOL_TUTOR = "TOOLBELT_TOOL_TUTOR";
export const TOOLBELT_TOOL_LAUNCH = "TOOLBELT_TOOL_LAUNCH";
export const TOOLBELT_TOOL_INSIGHT = "TOOLBELT_TOOL_INSIGHT";

export const TOOLBELT_VIEW_ALL_MODE = "view all";
export const TOOLBELT_RECENTLY_USED_MODE = "recently used";

export const TOOL_INSIGHT = {
  key: TOOLBELT_TOOL_INSIGHT,
  customId: 'icetoolbelt-insight-',
  title: "",
  iClass: "zmdi zmdi-chart insight-icon",
  iContent: "",
  capability: "",
  url: "",
  dragAndDropEnabled: false,
  isEnabled: true
}

export default [
  {
    key: TOOLBELT_TOOL_TAGS,
    id: "tags",
    customId: "icetoolbelt-tags-btn",
    title: "Tags",
    iClass: "zmdi zmdi-hc-fw zmdi-assignment tool-icon",
    iContent: "",
    capability: "tags",
    url: "/tags/#toolbelt",
    dragAndDropEnabled: true,
    isEnabled: true
  },
  {
    key: TOOLBELT_TOOL_TUTOR,
    id: "tutor",
    customId: "icetoolbelt-tutor-btn",
    title: "Tutor",
    iClass: "zmdi zmdi-hc-fw zmdi-graduation-cap tool-icon",
    iContent: "",
    capability: "tutor",
    url: "/tutor/#toolbelt",
    dragAndDropEnabled: false,
    isEnabled: false
  },
  {
    key: TOOLBELT_TOOL_LAUNCH,
    id: "launch",
    customId: "icetoolbelt-launch-btn",
    title: "Ctrl-Enter or Click to open Launch",
    iClass: "zmdi zmdi-hc-2x zmdi-apps apps-icon",
    iContent: "",
    capability: "",
    url: undefined,
    dragAndDropEnabled: false,
    isEnabled: true
  }
];
