import {
  SET_CONFIG_PROVIDERS_LOADING,
  SET_CONFIG_PROVIDERS_SUCCESS,
  SET_CONFIG_PROVIDERS_FAILURE,
  SET_ANALYTICS_INITIALIZED,
  SET_ANALYTICS_GOOGLE_TRACKING_ID
} from "../actions/config.action";

const initialState = {
  loading: false,
  providers: [],
  gtag: {
    initialized: false,
    trackingId: undefined
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CONFIG_PROVIDERS_LOADING:
      return { ...state, loading: true };
    case SET_CONFIG_PROVIDERS_SUCCESS:
      return { ...state, providers: action.payload, loading: false };
    case SET_CONFIG_PROVIDERS_FAILURE:
      return { ...state, loading: false };
    case SET_ANALYTICS_INITIALIZED:
      return { ...state, gtag: { ...state.gtag, initialized: action.payload } };
    case SET_ANALYTICS_GOOGLE_TRACKING_ID:
      return { ...state, gtag: { ...state.gtag, trackingId: action.payload } };
    default:
      return state;
  }
};
