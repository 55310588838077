import { AuthService } from "src/utils/network/AuthService";
import {
  setAppConfig,
  setAppToolbeltApps,
  setAppToolbeltTools,
  SET_RECENTLY_USED_APPS,
  SET_LAUNCH_LAST_MODE
} from "./app-config.action";
import UserStorage from "src/utils/storage/UserStorage";
import { ActivityService } from "src/utils/network/ActivityService";
import { AppService } from 'src/utils/network/AppService';
import { initInsights } from "./insight.action";

export const SIGN_IN_LOADING = "signin/loading";
export const SIGN_IN_SUCCESS = "signin/success";
export const SIGN_IN_FAILURE = "signin/failure";
export const SIGN_OUT_LOADING = "signout/loading";
export const SIGN_OUT_SUCCESS = "signout/success";
export const SIGN_OUT_FAILURE = "signout/failure";
export const AUTH_LAST_APP = "auth/lastapp";
export const REDIRECT_AFTER_LOGIN = "auth/login-redirect/should-redirect";

export const setLogin = (type, payload = undefined) => ({
  type,
  payload
});

export const login = (provider) => {
  return async (dispatch, getState) => {
    dispatch(setLogin(SIGN_IN_FAILURE, undefined));
    dispatch(setLogin(SIGN_IN_LOADING, true));
    const activityService = new ActivityService();
    const authService = new AuthService();
    const appService = new AppService();
    authService.openProviderWindow(provider);
    authService
      .providerWindowClosed()
      .then(() => dispatch(setLogin(SIGN_IN_LOADING, false)));

    try {
      const token = await authService.getLoginToken();

      try {
        // Who Am I
        const respWhoAmI = await authService.getWhoAmI(token);
        authService.populateWhoAmI(respWhoAmI);
      } catch (e) {
        console.log(e);
      }

      try {
        // Toolbelt Config
        const lastMode = await authService.getLaunchLastMode();
        const apps = await appService.getApps();
        if (lastMode) {
          dispatch(setAppConfig(SET_LAUNCH_LAST_MODE, lastMode));
        }
        dispatch(setAppToolbeltApps(apps, UserStorage.getCapabilities()));
        dispatch(setAppToolbeltTools());
      } catch (e) {
        console.log(e);
      }

      try {
        const recentlyUsedApps = await authService.getRecentlyUsedApps();
        const accessibleApps = getState().appConfig.toolbelt.apps.map(app => app.url);
        const accessibleRecentlyUsedApps = recentlyUsedApps.filter(app => {
          return accessibleApps.indexOf(app) !== -1;
        });
        dispatch(setAppConfig(SET_RECENTLY_USED_APPS, accessibleRecentlyUsedApps));
      } catch (e) {
        console.log(e);
      }

      try {
        // Toolbelt Pinned Insights and insights details
        dispatch(initInsights());
      } catch (e) {
        console.log(e);
      }

      try {
        // Short trial name
        const shortTrialName = await authService.getShortTrialName();
        authService.populateShortTrialName(shortTrialName);
      } catch (e) {
        console.log(e);
      }

      try {
        // Last App
        const respLastApp = await authService.getLastApp();
        const accessibleApps = getState().appConfig.toolbelt.apps.map(app => app.url);
        if (accessibleApps.indexOf(respLastApp) !== -1) {
          dispatch(setLogin(AUTH_LAST_APP, respLastApp));
        } else {
          await authService.deleteLastApp();
        }
      } catch (e) {
        console.log(e);
      }

      // Server & Auth Storage
      authService.populateServer();
      authService.populateAuthMethod(provider.name);

      // Activity Service
      await activityService.logActivity("Known user identified");
      await activityService.logActivity("User login");
      await activityService.uploadDeviceInformation();

      dispatch(setLogin(SIGN_IN_SUCCESS));
    } catch (err) {
      let error = "";
      if (err instanceof Error) {
        error = err.message;
      } else {
        error = err;
      }
      dispatch(setLogin(SIGN_IN_FAILURE, error));
    }
  };
};

export const logout = () => {
  return async (dispatch) => {
    dispatch(setLogin(SIGN_OUT_LOADING, true));
    const authService = new AuthService();
    try {
      await authService.logout();
      authService.clearStorage();
      dispatch(setLogin(SIGN_OUT_SUCCESS));
      return true;
    } catch {
      authService.clearStorage();
      dispatch(setLogin(SIGN_OUT_LOADING, false));
      return false;
    }
  };
};
