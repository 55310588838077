import React from 'react';
import PropTypes from "prop-types";
import ToolbeltApp from "../ToolbeltApp";

const AppsList = ({ apps, appsClass = '' }) => {
  return (
    <ul className={`apps-list ${appsClass}`}>
      {apps.map((app) => (
        <ToolbeltApp key={app.title} app={app} />
      ))}
  </ul>
  )
}

AppsList.propTypes = {
  apps: PropTypes.array,
  appsClass: PropTypes.string
};

export default AppsList;