import axios from "axios";

const requestConfig = {
  method: "",
  url: "",
  params: {},
  data: {},
  headers: {},
  timeout: 30000
};

class Http {
  static request(config = requestConfig) {
    return axios
      .request({
        method: config.method,
        url: config.url,
        params: config.params,
        data: config.data,
        headers: config.headers,
        timeout: requestConfig.timeout
      })
  }

  static get({ url = "", params = {}, headers = {}, timeout = 30000 }) {
    return Http.request({ method: "get", url, params, headers, timeout });
  }

  static post({ url = "", params = {}, data = {}, headers = {} }) {
    // remove default Content-Type header because empty requests do not work.
    delete axios.defaults.headers.post["Content-Type"];
    return axios.post(url, data, {
      headers: headers,
      params: params
    });
  }

  static put({ url = "", params = {}, data = {}, headers = {} }) {
    return axios.put(url, data, {
      headers: headers
    });
  }

  static delete({ url = "", params = {}, headers = {} }) {
    return Http.request({ method: "delete", url, params, headers });
  }
}

export default Http;
