import { useDispatch, useSelector } from "react-redux";
import {
  setAppConfig,
  SET_APP_CONFIG_HOME_ANIMATION_PROGRESS,
} from "src/store/actions/app-config.action";
import { useState, useEffect } from "react";
import { BasicFadeAnimationState } from "src/constants/animations";
import { useAnimate } from "./useAnimate";

export const useHomeAnimation = () => {
  const dispatch = useDispatch();
  const [animate] = useAnimate();
  const config = useSelector((state) => state.appConfig);

  const homeElementsShown = config.homeAnimations.homeElementsShown;
  const homeAnimationInProgress = config.homeAnimations.homeAnimationInProgress;
  const homeElementsShouldAnimateOnLoad =
    config.homeAnimations.homeElementsShouldAnimateOnLoad;

  const [animationState, setAnimationState] = useState(
    BasicFadeAnimationState.CLOSED
  );

  useEffect(() => {
    if (homeElementsShouldAnimateOnLoad) {
      setAnimationState(
        homeElementsShown
          ? BasicFadeAnimationState.CLOSED
          : BasicFadeAnimationState.OPEN
      );
    } else {
      setAnimationState(
        homeElementsShown
          ? BasicFadeAnimationState.OPEN
          : BasicFadeAnimationState.CLOSED
      );
    }
  }, [homeElementsShown, homeElementsShouldAnimateOnLoad]);

  useEffect(() => {
    if (homeAnimationInProgress) return;
    if (
      homeElementsShouldAnimateOnLoad &&
      homeElementsShown &&
      animationState === BasicFadeAnimationState.CLOSED
    ) {
      animate(
        450,
        () => {
          setAnimationState(BasicFadeAnimationState.OPENING);
          dispatch(setAppConfig(SET_APP_CONFIG_HOME_ANIMATION_PROGRESS, true));
        },
        () => {
          setAnimationState(BasicFadeAnimationState.OPEN);
          dispatch(setAppConfig(SET_APP_CONFIG_HOME_ANIMATION_PROGRESS, false));
        }
      );
    }
  }, [
    dispatch,
    animate,
    animationState,
    homeElementsShown,
    homeAnimationInProgress,
    homeElementsShouldAnimateOnLoad,
  ]);

  return [animationState];
};
