import { Service } from "src/utils/network/Service";
import Storage from "src/utils/storage/Storage";
import Http from "src/utils/network/Http";
import moment from "moment";

class IceTokenService extends Service {
  constructor(extendBySeconds) {
    super();
    this.extendBySeconds = extendBySeconds;
    this.extendsToken = false;
  }

  getExpiryDate() {
    return Storage.get("expires_at");
  }

  setExpiryDate(newExpiryDate) {
    Storage.set("expires_at", newExpiryDate);
  }

  getSecondsUntilExpire() {
    return moment(this.getExpiryDate()).diff(moment(), "seconds");
  }

  extend() {
    !this.extendsToken && this.extendToken();
  }

  extendToken() {
    this.extendsToken = true;
    Http.put({
      url: `${this.baseUrl}/v1/login/extend/${this.extendBySeconds}`,
      headers: { ...this.getAuthHeader() }
    })
      .then(() => {
        this.extendsToken = false;
        const newExpiryDate = moment()
          .add(this.extendBySeconds, "seconds")
          .format();
        this.setExpiryDate(newExpiryDate);
      })
      .catch(() => {
        this.extendsToken = false;
      });
  }
}

export default IceTokenService;
