import { getHost } from "src/utils/utils";
import { Service } from "./Service";
import Storage from "src/utils/storage/Storage";
import Http from "./Http";
import UserStorage from "../storage/UserStorage";
import { AUTH_ERROR_MESSAGE } from "src/constants/auth";

export class AuthService extends Service {
  openProviderWindow(provider) {
    this.loginWindow = window.open(
      `${getHost()}/v1/login/using/${provider.name}?origin=${encodeURIComponent(
        getHost()
      )}`
    );
  }

  providerWindowClosed() {
    return new Promise((resolve) => {
      const timer = setInterval(() => {
        if (this.loginWindow.closed) {
          clearInterval(timer);
          resolve(true);
        }
      }, 500);
    });
  }

  async getLoginToken() {
    if (process.env.NODE_ENV === "development" && process.env.REACT_APP_ICEHOST) {
      return process.env.REACT_APP_ICETOKEN;
    }

    return new Promise((resolve, reject) => {
      window.handleLoginSuccess = (msg) => {
        delete window.handleLoginSuccess;
        if (typeof msg !== "string") {
          reject(AUTH_ERROR_MESSAGE);
        }
        if (msg.indexOf("OK:") === 0) {
          const token = msg.slice("OK:".length);
          resolve(token);
        }
        if (msg.indexOf("ERROR:") === 0) {
          reject(msg.slice("ERROR:".length));
        }
        reject(AUTH_ERROR_MESSAGE);
      };
    });
  }

  async getLastApp() {
    try {
      const res = await Http.get({
        url: `/v1/userpreferences/${encodeURIComponent(
          UserStorage.getEmail()
        )}/last_app_url`,
        headers: { ...this.getAuthHeader(), ...this.getAcceptTypeJsonHeader() }
      });
      return res.data.result.value;
    } catch {
      return undefined;
    }
  }

  async setLastApp(appUrl) {
    try {
      const res = await Http.put({
        url: `${getHost()}/v1/userpreferences/${encodeURIComponent(
          UserStorage.getEmail()
        )}/last_app_url`,
        headers: {
          ...this.getAuthHeader(),
          ...this.setContentTypeJsonHeader()
        },
        data: { value: appUrl }
      });
      return res.data.result.value;
    } catch {
      return undefined;
    }
  }

  async deleteLastApp() {
    return await Http.delete({
      url: `/v1/userpreferences/${encodeURIComponent(
        UserStorage.getEmail()
      )}/last_app_url`,
      headers: {
        ...this.getAuthHeader()
      }
    });
  }

  async getWhoAmI(token) {
    return await Http.get({
      url: `${this.baseUrl}/v1/whoami`,
      headers: { Authorization: `Bearer ${token}` }
    });
  }

  async putWhoAmI(data) {
    return await Http.put({
      url: `${this.baseUrl}/v1/whoami`,
      headers: { ...this.getAuthHeader() },
      data: data
    });
  }

  populateWhoAmI(resp) {
    Object.keys(resp.data.result).forEach((k) => {
      Storage.set(k, resp.data.result[k]);
    });
  }

  async getShortTrialName() {
    try {
      const res =  await Http.get({
        url: `${getHost()}/v1/trialparams`,
        headers: { ...this.getAuthHeader() }
      });
      const trialparams = res.data.result.trialparams;
      const shortTrialName = trialparams.find(trialparam => trialparam.key === "short_trial_name");
      return shortTrialName;
    } catch {
      return undefined;
    }
  }

  populateShortTrialName(shortTrialName) {
    if (shortTrialName) {
      Storage.set(shortTrialName.key, shortTrialName.value);
      Storage.set("trial", shortTrialName.value);
    } else {
      Storage.set("short_trial_name", undefined);
      Storage.set("trial", undefined);
    }
  }

  populateServer() {
    Storage.set("server", getHost());
  }

  populateAuthMethod(provider) {
    Storage.set("auth_method", provider);
  }

  async logout() {
    return await Http.delete({
      url: `${getHost()}/v1/logout`,
      headers: { ...this.getAuthHeader() }
    });
  }

  clearStorage() {
    Storage.getAllKeys().forEach((storageKey) => {
      if (storageKey === "dismiss_optimal_browser") return;
      Storage.remove(storageKey);
    });
  }

  async getRecentlyUsedApps() {
    try {
      const res = await Http.get({
        url: `/v1/userpreferences/${encodeURIComponent(
          UserStorage.getEmail()
        )}/recently_used_apps`,
        headers: {
          ...this.getAuthHeader(),
          ...this.getAcceptTypeJsonHeader(),
          ...this.setSkipTutorHeader()
        }
      });
      return res.data.result.value;
    } catch {
      return [];
    }
  }

  async updateRecentlyUsedApps(apps) {
    try {
      const res = await Http.put({
        url: `/v1/userpreferences/${encodeURIComponent(
          UserStorage.getEmail()
        )}/recently_used_apps`,
        headers: {
          ...this.getAuthHeader(),
          ...this.setContentTypeJsonHeader(),
          ...this.setSkipTutorHeader()
        },
        data: { value: apps }
      });
      return res.data.result.value;
    } catch {
      return undefined;
    }
  }

  async getLaunchLastMode() {
    try {
      const res = await Http.get({
        url: `/v1/userpreferences/${encodeURIComponent(
          UserStorage.getEmail()
        )}/launch_last_mode`,
        headers: {
          ...this.getAuthHeader(),
          ...this.getAcceptTypeJsonHeader(),
          ...this.setSkipTutorHeader()
        }
      });
      return res.data.result.value;
    } catch {
      return undefined;
    }
  }

  async updateLaunchLastMode(mode) {
    try {
      const res = await Http.put({
        url: `/v1/userpreferences/${encodeURIComponent(
          UserStorage.getEmail()
        )}/launch_last_mode`,
        headers: {
          ...this.getAuthHeader(),
          ...this.setContentTypeJsonHeader(),
          ...this.setSkipTutorHeader()
        },
        data: { value: mode }
      });
      return res.data.result.value;
    } catch {
      return undefined;
    }
  }

}
