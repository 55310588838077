import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLauncherAnimation } from "src/hooks/useLauncherAnimation";
import { BasicFadeAnimationKey } from "src/constants/animations";
import { useOperationMode } from "src/hooks/useOperationMode";
import PropTypes from "prop-types";


import AppsSection from './menu/AppsSection';
import SearchSection from './menu/SearchSection';
import { TOOLBELT_RECENTLY_USED_MODE, TOOLBELT_VIEW_ALL_MODE } from "src/constants/tools";
import { setLaunchLastMode } from "src/store/actions/app-config.action";

const ToolbeltAppLauncher = ({ onRefSet = (ref) => {} }) => {
  const dispatch = useDispatch();
  const searchValue = useSelector((state) => state.appConfig.toolbelt.searchValue);
  const lastMode = useSelector((state) => state.appConfig.toolbelt.lastMode);

  const [operationModeClasses] = useOperationMode();
  const [animationState] = useLauncherAnimation();
  const launcherClasses = [
    "toolbelt-app-launcher-wrapper",
    animationState,
    ...operationModeClasses
  ]

  useEffect(() => {
    if (searchValue) {
      dispatch(setLaunchLastMode(TOOLBELT_VIEW_ALL_MODE));
    }
  }, [searchValue]);

  return (
    <div
      ref={(ref) => onRefSet(ref)}
      className={launcherClasses.join(" ")}
      data-animation={BasicFadeAnimationKey[animationState]}>
      <div className="select-mode">
        <div className={`mode ${lastMode === TOOLBELT_VIEW_ALL_MODE ? 'active' : ''}`}
             onClick={() => dispatch(setLaunchLastMode(TOOLBELT_VIEW_ALL_MODE))}>
          View All
        </div>
        <div className={`mode ${lastMode === TOOLBELT_RECENTLY_USED_MODE ? 'active' : ''}`}
             onClick={() => dispatch(setLaunchLastMode(TOOLBELT_RECENTLY_USED_MODE))}>
          Recently Used
        </div>
      </div>
      <AppsSection activeMode={lastMode} />
      <SearchSection />
    </div>
  );
};

ToolbeltAppLauncher.propTypes = {
  onRefSet: PropTypes.func
}

export default ToolbeltAppLauncher;
