export const BasicFadeAnimationState = {
  OPENING: "opening",
  OPEN: "open",
  CLOSED: "closed",
  CLOSING: "closing",
};

export const BasicFadeAnimationKey = {
  [BasicFadeAnimationState.OPENING]: "opening",
  [BasicFadeAnimationState.CLOSING]: "closing",
};
