import { useEffect, useCallback, useState, useRef } from "react";
import { ConfigService } from "src/utils/network/ConfigService";
import { useSelector, useDispatch } from "react-redux";
import {
  setConfig,
  SET_ANALYTICS_INITIALIZED,
  SET_ANALYTICS_GOOGLE_TRACKING_ID
} from "src/store/actions/config.action";

import ReactGA from "react-ga4";

export const useGTAG = () => {
  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, []);
  const [eventListenerAttached, setEventListenerAttached] = useState(false);
  const [sentPageViews, setSentPageViews] = useState([]);
  const latestSentPageViews = useRef();

  const gtagInitialized = useSelector(
    (state) => state.configReducer.gtag.initialized
  );

  const registerPageView = useCallback((pathname) => {
    const isSent =
      latestSentPageViews.current.find((pv) => pv === pathname) !== undefined;

    const containPII = () => {
      return pathname.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi) ||
      pathname.match(/([a-zA-Z0-9._-]+%40[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi);
    }

    if (isSent || containPII()) return;
    setSentPageViews([...latestSentPageViews.current, pathname]);
    ReactGA.set({ page: pathname });
    ReactGA.send({ hitType: "pageview", page: pathname});

    /**
     * This function will make sure that the sentPageViews array
     * will get cleaned after one second.
     * ! Required by some apps that will update the location to the
     * same value multiple times on load. The 1000ms timeout should be
     * just enough to avoid that behaviour.
     */
    const cleanPageViews = () => {
      setTimeout(() => {
        setSentPageViews([
          ...latestSentPageViews.current.filter((pv) => pv !== pathname)
        ]);
      }, 1000);
    };
    cleanPageViews();
  }, []);

  const getPathname = () => {
    return window.location.href.split(window.location.origin)[1];
  };

  /**
   * Updating the latest page views ref
   */
  useEffect(() => {
    latestSentPageViews.current = sentPageViews;
  }, [sentPageViews]);

  /**
   * Getting the tracking ID
   */
  useEffect(() => {
    const getTrackingId = async () => {
      const configService = new ConfigService();
      try {
        const resp = await configService.getGoogleTrackingId();
        const trackingId = resp.data.result.google_analytics.trackingId;
        const siteSpeedSampleRate = resp.data.result.google_analytics.siteSpeedSampleRate;

        if (trackingId) {
          ReactGA.initialize(trackingId, {
            gaOptions: {
              siteSpeedSampleRate: siteSpeedSampleRate ?  parseInt(siteSpeedSampleRate) : 1
            }
          });
          stableDispatch(setConfig(SET_ANALYTICS_INITIALIZED, true));
          stableDispatch(
            setConfig(SET_ANALYTICS_GOOGLE_TRACKING_ID, trackingId)
          );

          registerPageView(getPathname());
        }
      } catch (e) {
        console.log("Could not initialize Google Analytics.", e);
      }
    };
    getTrackingId();
  }, [stableDispatch, registerPageView]);

  useEffect(() => {
    if (gtagInitialized && !eventListenerAttached) {
      const registerPageViewListener = () => {
        registerPageView(getPathname());
      };
      window.addEventListener("popstate", registerPageViewListener);
      setEventListenerAttached(true);
    }
  }, [
    gtagInitialized,
    eventListenerAttached,
    setEventListenerAttached,
    registerPageView
  ]);

  return [registerPageView];
};
