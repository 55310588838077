import React from 'react';
import PropTypes from "prop-types";
import { useSelector } from 'react-redux';
import AppsList from './AppsList';

const FilteredAppsList = ({ apps, appsClass }) => {
  const searchValue = useSelector((state) => state.appConfig.toolbelt.searchValue);
  const filteredApps = searchValue ? apps.filter(app => app.title.toLowerCase().includes(searchValue.toLowerCase())) : apps;

  return (
    <AppsList apps={filteredApps} appsClass={appsClass} />
  )
}

FilteredAppsList.propTypes = {
  apps: PropTypes.array,
  appsClass: PropTypes.string
}

export default FilteredAppsList;