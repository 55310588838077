import React from "react";
import PropTypes from "prop-types";

import ToolbeltNavigation from "./ToolbeltNavigation";

import { ReactComponent as EncapsiaLogo } from "src/images/icons/encapsia-icon.svg";
import { useOperationMode } from "src/hooks/useOperationMode";

const ToolbeltFooter = ({ onRefSet = (ref) => {} }) => {
  const [operationModeClasses] = useOperationMode();
  const toolbeltFooterClasses = ["toolbelt-footer-bg", ...operationModeClasses];
  return (
    <div
      ref={(ref) => onRefSet(ref)}
      className={toolbeltFooterClasses.join(" ")}>
      <div className="encapsia-logo-copyright-container">
        <div className="encapsia-logo">
          <EncapsiaLogo fill="#243142" />
        </div>
        <div className="encapsia-copyright">Step Forward with Encapsia</div>
      </div>
      <div className="toolbelt-navigation-wrapper">
        <ToolbeltNavigation />
      </div>
    </div>
  );
};

ToolbeltFooter.propTypes = {
  onRefSet: PropTypes.func
}

export default ToolbeltFooter;
