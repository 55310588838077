import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { toggleInsight } from "src/store/actions/insight.action";

const ToolbeltInsightButton = ({ tool }) => {
  const dispatch = useDispatch();
  const activeInsights = useSelector((state) => state.toolbeltInsights.activeInsights);
  const insightsDetails = useSelector(state => state.toolbeltInsights.insights);
  const insightTitle = insightsDetails[tool.key] ? insightsDetails[tool.key].title : '';

  const getActiveClass = () => {
    return activeInsights.indexOf(tool.key) > -1 ? 'active' : "";
  }

  const toolClassNames = [
    "tool-button",
    "icetoolbelt-insight-btn",
    getActiveClass()
  ];

  return (
    <div
      key={tool.key}
      id={tool.customId}
      className={toolClassNames.join(" ")}
      onClick={() => {
        dispatch(toggleInsight(tool.key))
      }}>
      <a role="button" title={`${insightTitle}`}>
        <i className={tool.iClass}>{tool.iContent}</i>
      </a>
    </div>
  );
};

ToolbeltInsightButton.propTypes = {
  tool: PropTypes.object
};

export default ToolbeltInsightButton;
