import { tracker } from "src/utils/activityTracker";
import { useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import IceTokenService from "src/utils/heartbeat/IceTokenService";
import {
  setUserInactive,
  setDisplayWarning,
  pollWhoAmI
} from "src/store/actions/heartbeat.action";
import { TIMEOUT_POLLING_TIME } from "src/constants/timers";
import { useTrialparams } from "./useTrialparams";
import { useSessionLifespan } from "./useSessionLifespan";

export const useActivityTracker = () => {
  const dispatch = useDispatch();
  const [trialparams, loadingTrialparams] = useTrialparams();
  const [sessionLifespan, loadingSessionLifespan] = useSessionLifespan();

  const check = useCallback((iceToken, extendBySeconds) => {
    const secondsOfInactivity = tracker.getSecondsAgo();
    if (secondsOfInactivity > extendBySeconds) {
      dispatch(setUserInactive());
    } else {
      if (secondsOfInactivity > trialparams.user_inactivity_timeout) {
        dispatch(setDisplayWarning(true));
      } else {
        dispatch(setDisplayWarning(false));
        if (
          iceToken.getSecondsUntilExpire() < trialparams.user_inactivity_window
        ) {
          iceToken.extend();
        }
      }
    }
  }, []);

  useEffect(() => {
    let checkInterval = null;
    if (!loadingTrialparams && !loadingSessionLifespan) {
      let extendBySeconds =
        trialparams.user_inactivity_timeout +
        trialparams.user_inactivity_window;

      // Limiting the session extend duration to the server-configured session lifespan
      // or else the /login/extend call will fail
      if (extendBySeconds > sessionLifespan) {
        extendBySeconds = sessionLifespan;
      }

      const iceToken = new IceTokenService(extendBySeconds);
      iceToken.extend();

      tracker.start();
      checkInterval = setInterval(
        () => check(iceToken, extendBySeconds),
        TIMEOUT_POLLING_TIME
      );

      dispatch(pollWhoAmI());
    }

    return () => {
      tracker.stop();
      clearInterval(checkInterval);
    };
  }, [loadingTrialparams, loadingSessionLifespan]);

  return [tracker];
};
