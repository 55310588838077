import { SET_LANG_STRINGS } from "../actions/lang.action";

const initialState = {
  strings: require("src/i18n/en.json")
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_LANG_STRINGS:
      return {
        ...state,
        strings: require(`src/i18n/${action.payload.slug}.json`)
      };
    default:
      return state;
  }
};
