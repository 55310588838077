import React, { useState } from "react";
import UserBar from "src/components/home/UserBar";
import Toolbelt from "../home/Toolbelt/Toolbelt";

const HomeLayout = () => {
  const [backgroundImageClasses] = useState([
    "large-image-negative-z",
    "large-image-background",
    "image-loaded"
  ]);
  return (
    <div className="launch-default-body encapsia-default-body">
      <div className={backgroundImageClasses.join(" ")}></div>
      <main className="launch-container">
        <UserBar />
        <Toolbelt />
      </main>
    </div>
  );
};

export default HomeLayout;
