import { loadState } from "src/utils/storage/storePersistance";
import {
  SET_ALL_INSIGHTS,
  SET_TOOLBELT_PINNED_INSIGHTS,
  SET_TOOLBELT_INSIGHT_ACTIVE,
  SET_EXCEED_LIMIT_WARNING,
  SET_ALREADY_PINNED_INSIGHT_WARNING,
  SET_INSIGHT_COORDINATES,
  SET_INSIGHTS_ACCESS,
  SET_FOCUSED_INSIGHT,
  SET_ALREADY_OPENED_INSIGHT_WARNING
} from "src/store/actions/insight.action";

const initialInsightsCoordinates = loadState('insightsCoordinates') || {};

const initialState = {
  insights: {},
  pinnedInsights: [],
  activeInsights: [],
  insightsCoordinates: initialInsightsCoordinates,
  focusedInsight: null,
  showWarning: false,
  alreadyPinned: false,
  alreadyOpened: false,
  insightsAccess: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ALL_INSIGHTS:
      return {
        ...state,
        insights: action.payload
      };
    case SET_TOOLBELT_PINNED_INSIGHTS:
      return {
        ...state,
        pinnedInsights: action.payload
      };
    case SET_TOOLBELT_INSIGHT_ACTIVE:
      return {
        ...state,
        activeInsights: action.payload
      };
    case SET_EXCEED_LIMIT_WARNING:
      return {
        ...state,
        showWarning: action.payload
      }
    case SET_ALREADY_PINNED_INSIGHT_WARNING:
      return {
        ...state,
        alreadyPinned: action.payload
      }
    case SET_INSIGHT_COORDINATES:
      return {
        ...state,
        insightsCoordinates: {
          ...state.insightsCoordinates,
          ...action.payload
        }
      }
    case SET_INSIGHTS_ACCESS:
      return {
        ...state,
        insightsAccess: action.payload
      }
    case SET_FOCUSED_INSIGHT:
      return {
        ...state,
        focusedInsight: action.payload
      }
    case SET_ALREADY_OPENED_INSIGHT_WARNING:
      return {
        ...state,
        alreadyOpened: action.payload
      }
    default:
      return state;
  }
};
