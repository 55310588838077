import React from "react";
import Proptypes from "prop-types";

const WebApp = ({ app, children }) => {
  return  (
    <a
    href={app.url}
    title={`${app.description}`}
    target={app.targetBlank ? '_blank' : '_self'}
    rel="noreferrer"
    >
      {children}
    </a>
  )
}


WebApp.propTypes = {
  app: Proptypes.object,
  children: Proptypes.element
};

export default WebApp;