import React, { useEffect } from "react";
import ToolbeltFooter from "./ToolbeltFooter";
import ToolbeltToolPanel from "./ToolbeltToolPanel";
import ToolbeltAppLauncher from "./ToolbeltAppLauncher";
import { useHomeAnimation } from "src/hooks/useHomeAnimation";
import TimeoutWarning from "./TimeoutWarning";
import { useSelector, useDispatch } from "react-redux";
import { useActivityTracker } from "src/hooks/useActivityTracker";
import { useGTAG } from "src/hooks/useGTAG";
import { LOGOUT_ROUTE } from "src/constants/routes";
import { getHost } from "src/utils/utils";
import { logout } from "src/store/actions/auth.action";
import { useToolbeltComponentsClickDetector } from "src/hooks/useToolbeltComponentsClickDetector";
import InsightContainer from "./InsightContainer";
import InsightWarning from "./InsightWarning";
import { EXCEED_LIMIT_MESSAGE, OPENED_INSIGHT_MESSAGE, PINNED_INSIGHT_MESSAGE } from "src/constants/insights";

const Toolbelt = () => {
  useGTAG();

  const dispatch = useDispatch();
  const toolbelt = useSelector((state) => state.appConfig.toolbelt);
  const activeTool = toolbelt.activeTool;
  const appLauncherClosed = toolbelt.appLauncherClosed;

  const [tracker] = useActivityTracker();
  const [animationState] = useHomeAnimation();
  const userInactive = useSelector(
    (state) => state.heartbeatReducer.userInactive
  );
  const showExceedLimit = useSelector((state) => state.toolbeltInsights.showWarning);
  const showAlreadyPinned = useSelector((state) => state.toolbeltInsights.alreadyPinned);
  const showAlreadyOpened = useSelector((state) => state.toolbeltInsights.alreadyOpened);

  const {
    registerAppLauncherClickDetector,
    registerAppFooterClickDetector
  } = useToolbeltComponentsClickDetector();

  // needed for Conduct
  const getInitialState = () => {
    return activeTool !== undefined && activeTool.id === "tags" ? "initial-state" : "";
  };

  const toolbeltClasses = [
    "encapsiatoolbelt",
    animationState,
    getInitialState()
  ];

  useEffect(() => {
    if (userInactive) {
      const nextUrl = encodeURIComponent(window.location.pathname);
      dispatch(logout());
      window.location.assign(
        `${getHost()}/launch${LOGOUT_ROUTE.path}&next=${nextUrl}`
      );
    }
  }, [userInactive]);

  return (
    <div id="icetoolbelt-container" className={toolbeltClasses.join(" ")}>
      <ToolbeltToolPanel tracker={tracker} />
      <InsightContainer />
      <ToolbeltAppLauncher
        onRefSet={(ref) => registerAppLauncherClickDetector(ref)}
      />
      <ToolbeltFooter onRefSet={(ref) => registerAppFooterClickDetector(ref)} />
      <TimeoutWarning />
      <InsightWarning key="exceed-insights-limit" show={showExceedLimit} message={EXCEED_LIMIT_MESSAGE} />
      <InsightWarning key="insight-already-pinned" show={showAlreadyPinned} message={PINNED_INSIGHT_MESSAGE} />
      <InsightWarning key="insight-already-opened" show={showAlreadyOpened} message={OPENED_INSIGHT_MESSAGE} />
    </div>
  );
};

export default Toolbelt;
