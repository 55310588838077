import { DEFAULT_TRIALPARAMS } from "src/constants/trialparams";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { getHost, listToObject } from "src/utils/utils";
import { setUserInactive } from "src/store/actions/heartbeat.action";
import Http from "src/utils/network/Http";
import Storage from "src/utils/storage/Storage";

export const useTrialparams = () => {
  const [trialparams, setTrialparams] = useState(DEFAULT_TRIALPARAMS);
  const [loadingTrialparams, setLoadingTrialparams] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetch = async () => {
      try {
        const response = await Http.get({
          url: `${getHost()}/v1/trialparams`,
          headers: { Authorization: `Bearer ${Storage.get("token")}` }
        });
        const requestedTrialparams = listToObject(
          response.data.result.trialparams
        );
        const extendedTrialparams = Object.assign(
          DEFAULT_TRIALPARAMS,
          requestedTrialparams
        );
        setTrialparams(extendedTrialparams);
        setLoadingTrialparams(false);
      } catch (err) {
        if (
          err &&
          err.response &&
          err.response.status &&
          err.response.status === 401
        ) {
          console.error(
            "Heartbeat tried to request trialparams, but wasn't authenticated, so going back to login app",
            err
          );
          dispatch(setUserInactive());
        } else {
          console.warn(
            "Heartbeat failed to request trial params. using defaults",
            err
          );
        }
        setLoadingTrialparams(false);
      }
    };
    fetch();
  }, []);
  return [trialparams, loadingTrialparams];
};
