import React from 'react';
import { useSelector } from "react-redux";
import AppsList from './AppsList';

const RecentlyUsedMode = () => {
  const toolbelt = useSelector((state) => state.appConfig.toolbelt);
  const recentlyUsedApps = toolbelt.apps.filter(app => toolbelt.recentlyUsedApps.indexOf(app.url) !== -1);
  recentlyUsedApps.sort((a, b) => {
    return toolbelt.recentlyUsedApps.indexOf(a.url) - toolbelt.recentlyUsedApps.indexOf(b.url)
  });

  return (
    <div>
    {recentlyUsedApps.length > 0  ? <AppsList apps={recentlyUsedApps} /> :
     <div className="no-recently-used-apps">You have no recently used apps.</div>}
    </div>
  )
}

export default RecentlyUsedMode;