import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import FilteredAppsList from './FilteredAppsList';
import { useSelector } from 'react-redux';
import { useAnimate } from 'src/hooks/useAnimate';

const CollapsibleMenu = ({ title, apps, isCollapsed }) => {
  const searchValue = useSelector((state) => state.appConfig.toolbelt.searchValue);
  const [collapsed, setCollapsed] = useState(isCollapsed);
  const [animationState, setAnimationState] = useState('');
  const [animate] = useAnimate();
  const toggleSection = () => {
    if (collapsed) {
      animate(
        300,
        () => {
          setAnimationState('expanding');
          setCollapsed(!collapsed);
        },
        () => {
          setAnimationState('');
        }
      );
    } else {
      animate(
        300,
        () => {
          setAnimationState('collapsing');
        },
        () => {
          setAnimationState('');
          setCollapsed(!collapsed);
        }
      );
    }
  }

  useEffect(() => {
    if (searchValue) {
      setCollapsed(false);
    }
  }, [searchValue]);

  return (
    <div className="menu-section">
      <div className="title-menu" onClick={toggleSection}>
        <span>{title}</span>
        <i className={`zmdi zmdi-hc-fw zmdi-caret-${collapsed ? 'up' : 'down'}`} />
      </div>
      {!collapsed && <FilteredAppsList apps={apps} appsClass={animationState} />}
    </div>
  )
};

CollapsibleMenu.propTypes = {
  title: PropTypes.string,
  apps: PropTypes.array,
  isCollapsed: PropTypes.bool
}

export default CollapsibleMenu;
