import React, { useState, useEffect } from "react";

import EncapsiaLogo from "encapsiadesign/img/encapsia_logo-dark.png";

import AuthProviders from "./AuthProviders";
import { HorizontalLine, LoadingSpinner, Banner } from "../reusable";
import BrowserWarning from "./BrowserWarning";

import { useSelector, useDispatch } from "react-redux";
import { setLogin, REDIRECT_AFTER_LOGIN } from "src/store/actions/auth.action";
import { GeolocationInstance } from "src/utils/Geolocation";
import Storage from "src/utils/storage/Storage";
import { useHelpdeskConfig } from "src/hooks/useHelpdeskConfig";
import { AUTH_FRIENDLY_MESSAGES, AUTH_ERROR_TITLE } from "src/constants/auth";

const AuthBox = () => {
  const dispatch = useDispatch();

  const auth = useSelector((state) => state.authReducer);
  const config = useSelector((state) => state.configReducer);
  const strings = useSelector((state) => state.langReducer.strings);

  const [loginBoxAnimations, setLoginBoxAnimations] = useState([]);

  // TODO: investigate if this variable is necessary
  const [readyForRedirect] = useState(false);
  const [externalContactLink] = useHelpdeskConfig();

  const redirectAfterLogin = () => {
    if (
      readyForRedirect &&
      auth.status.loggedIn &&
      !auth.loginRedirect.shouldRedirect
    ) {
      dispatch(setLogin(REDIRECT_AFTER_LOGIN, true));
    }
  };

  const getErrorMessage = () => {
    return AUTH_FRIENDLY_MESSAGES[auth.status.error] ?? auth.status.error;
  };

  /**
   * Running effect on login success
   */
  useEffect(() => {
    const loggedIn = auth.status.loggedIn;
    const splashExecuting = loginBoxAnimations.find(
      (a) => a === "fade-out-up-1"
    );
    let timeout;
    if (loggedIn && !splashExecuting && !auth.loginRedirect.shouldRedirect) {
      setLoginBoxAnimations([...loginBoxAnimations, "fade-out-up-1"]);
      dispatch(setLogin(REDIRECT_AFTER_LOGIN, true));
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [
    auth.status.loggedIn,
    auth.loginRedirect.shouldRedirect,
    loginBoxAnimations
  ]);

  useEffect(() => {
    if (
      Storage.get(GeolocationInstance.storageKey) !== undefined ||
      Storage.get(GeolocationInstance.storageKey) === false
    ) {
      return;
    }
    GeolocationInstance.getGeolocation().then((location) => {
      GeolocationInstance.stashGeolocation(location);
    });
  }, []);

  return (
    <>
      {redirectAfterLogin()}
      <section
        className="login-box"
        data-animation={loginBoxAnimations.join(" ")}>
        <div className="auth-logo-container logo">
          <div className="image-container">
            <a
              href="http://www.encapsia.com"
              rel="noopener noreferrer"
              target="_blank">
              <img
                src={EncapsiaLogo}
                className="img-responsive logo-image"
                alt=""
              />
            </a>
            <h1 className="new-generation-header">STEP FORWARD</h1>
          </div>
          <HorizontalLine />
          {auth.status.error && (
            <Banner
              type="danger"
              message={getErrorMessage()}
              title={AUTH_ERROR_TITLE}
              show
            />
          )}

          <div className="container_sign_in">{strings.LOGIN.SIGN_IN_WITH}</div>
        </div>
        <div className="auth-main-container">
          <AuthProviders />
          <p>
            Having problems? Contact our{" "}
            <a
              href={externalContactLink}
              className="underlined-anchor-primary">
              helpdesk
            </a>
          </p>
          <LoadingSpinner loading={config.loading || auth.status.loading} />
          <BrowserWarning />
        </div>
      </section>
    </>
  );
};

export default AuthBox;
