import { useState } from "react";
import { useSelector } from "react-redux";
import {
  OPERATION_MODE_FULL,
  OPERATION_MODE_TOOLBELT_MODULE
} from "src/constants/mode";

export const useOperationMode = () => {
  const operationModeConfig = useSelector(
    (state) => state.appConfig.operationMode
  );

  /**
   * Array instead of string for future support.
   */
  const getClassesByOperationMode = () => {
    switch (operationModeConfig) {
      case OPERATION_MODE_FULL:
        return ["op-mode-full"];
      case OPERATION_MODE_TOOLBELT_MODULE:
        return ["op-mode-toolbelt-module"];
      default:
        return [];
    }
  };

  const [operationModeCssClasses] = useState(getClassesByOperationMode());

  return [operationModeCssClasses];
};
