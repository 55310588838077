import React, { useState } from "react";
import Proptypes from "prop-types";
import InsightApp from "./InsightApp";
import WebApp from "./WebApp";

const ToolbeltApp = ({ app }) => {
  const [hover, setHover] = useState(false);
  const details = (
    <>
      <div className="app-icon" style={{background: app.color}}>
        <img src={app.iconUrl} />
      </div>
      <strong>{app.title}</strong>
    </>
  )
  return (
    <li className="app"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={hover ? {background : app.color} : null}>
      {app.id ? <InsightApp app={app}>{details}</InsightApp> : <WebApp app={app}>{details}</WebApp>}
    </li>
  );
};

ToolbeltApp.propTypes = {
  app: Proptypes.object
};

export default ToolbeltApp;
