import { useEffect, useState } from "react";

export const useWrapperClickDetector = () => {
  /**
   * If clicked, they won't trigger "refs" options.
   */
  const [whitelistRefs, setWhitelistRefs] = useState([]);
  const [refs, setRefs] = useState([]);

  const registerRef = ({ refs = [], whitelist = false }) => {
    whitelist ? setWhitelistRefs(refs) : setRefs(refs);
  };

  useEffect(() => {
    const handleClicks = (event) => {
      event.stopPropagation();
      refs.forEach((ref) => {
        if (!ref.ref.contains(event.target)) {
          let clickedAWhitelistedElement = false;
          for (const wref of whitelistRefs) {
            if (wref.ref.contains(event.target)) {
              clickedAWhitelistedElement = true;
            }
          }
          if (!clickedAWhitelistedElement) {
            ref.options.onOutsideClick();
          }
        }
      });
    };

    document.addEventListener("mousedown", handleClicks);
    return () => {
      document.removeEventListener("mousedown", handleClicks);
    };
  }, [refs, whitelistRefs]);

  return { registerRef };
};
