import Storage from "./Storage";

class UserStorage {
  static getToken() {
    return Storage.get("token");
  }

  static getName() {
    return Storage.get("name");
  }

  static getEmail() {
    return Storage.get("email");
  }

  static getRole() {
    return Storage.get("role");
  }

  static getCapabilities() {
    return Storage.get("capabilities");
  }
}

export default UserStorage;
