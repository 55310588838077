import Http from "src/utils/network/Http";

/**
 * Config OAuth Providers
 */
export const SET_CONFIG_PROVIDERS_LOADING = "config/providers/loading";
export const SET_CONFIG_PROVIDERS_SUCCESS = "config/providers/success";
export const SET_CONFIG_PROVIDERS_FAILURE = "config/providers/failure";

/**
 * Config Google Analytics
 */
export const SET_ANALYTICS_INITIALIZED = "config/analytics/initialized";
export const SET_ANALYTICS_GOOGLE_TRACKING_ID = "config/analytics/trackingId";

export const setConfig = (type, payload) => ({
  type,
  payload
});

export const setConfigProviders = (type, payload) => ({
  type,
  payload
});


export const getProviders = (serverUrl) => {
  return async (dispatch) => {
    dispatch(setConfigProviders(SET_CONFIG_PROVIDERS_LOADING));
    try {
      const resp = await Http.get({
        url: serverUrl,
        headers: { Accept: "application/json" }
      });
      dispatch(
        setConfigProviders(
          SET_CONFIG_PROVIDERS_SUCCESS,
          resp.data.result.oauth2_providers
        )
      );
    } catch (err) {
      if (err.toString().match("aborted")) {
        dispatch(getProviders(serverUrl));
      } else
      dispatch(setConfigProviders(SET_CONFIG_PROVIDERS_FAILURE, err));
    }
  };
};
