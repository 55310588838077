import { TOOLBELT_TOOL_LAUNCH } from "src/constants/tools";
import {
  setAppConfig,
  SET_APP_CONFIG_TOOLBELT_APP_LAUNCHER_CLOSED,
  SET_APP_CONFIG_TOOLBELT_ACTIVE_TOOL
} from "src/store/actions/app-config.action";
import { useDispatch, useSelector } from "react-redux";

export const useToolToggler = ({ tool }) => {
  const dispatch = useDispatch();
  const toolbelt = useSelector((state) => state.appConfig.toolbelt);

  const activeTool = toolbelt.activeTool;
  const appLauncherClosed = toolbelt.appLauncherClosed;
  const gotActiveTool = activeTool !== undefined;

  const didClickTool = () => {
    toggleOppositePanel();
    tool.key === TOOLBELT_TOOL_LAUNCH
      ? toggleAppLauncherClosed()
      : setActiveTool();
  };

  const setActiveTool = () => {
    gotActiveTool
      ? activeTool.key === tool.key
        ? dispatch(setAppConfig(SET_APP_CONFIG_TOOLBELT_ACTIVE_TOOL, undefined))
        : dispatch(setAppConfig(SET_APP_CONFIG_TOOLBELT_ACTIVE_TOOL, tool))
      : dispatch(setAppConfig(SET_APP_CONFIG_TOOLBELT_ACTIVE_TOOL, tool));
  };

  const toggleAppLauncherClosed = () => {
    dispatch(
      setAppConfig(
        SET_APP_CONFIG_TOOLBELT_APP_LAUNCHER_CLOSED,
        !appLauncherClosed
      )
    );
  };

  const toggleOppositePanel = () => {
    if (tool.key === TOOLBELT_TOOL_LAUNCH && toolbelt.appLauncherClosed) {
      // Close any tool iframe panel because launch menu is going to open.
      if (gotActiveTool) {
        dispatch(setAppConfig(SET_APP_CONFIG_TOOLBELT_ACTIVE_TOOL, undefined));
      }
    }
    if (tool.key !== TOOLBELT_TOOL_LAUNCH && !toolbelt.appLauncherClosed) {
      // Close the launch menu because an iframe is going to be shown
      dispatch(setAppConfig(SET_APP_CONFIG_TOOLBELT_APP_LAUNCHER_CLOSED, true));
    }
  };

  const getActiveClass = () => {
    if (tool.key === TOOLBELT_TOOL_LAUNCH) {
      return appLauncherClosed ? "" : "active";
    }
    return gotActiveTool && activeTool.key === tool.key ? "active" : "";
  };

  const getDisabledClass = () => {
    return !tool.isEnabled ? "disabled" : "";
  };

  return { didClickTool, getActiveClass, getDisabledClass, activeTool };
};
