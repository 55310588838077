export const AUTH_PROVIDERS = [
  {
    provider: "Authenticate",
    name: "authenticate"
  },
  {
    provider: "Google",
    name: "google"
  },
  {
    provider: "Microsoft",
    name: "azure"
  }
];

// Used when the link to the external location containing the helpdesk contact information is not configured/missing in the server configuration
export const DEFAULT_EXTERNAL_LINK = "https://encapsia.com/";
export const AUTH_ERROR_TITLE = "Access Denied";
export const AUTH_ERROR_MESSAGE = "Something went wrong while logging in.";
const NO_ACCOUNT_FOUND_FOR_STUDY = "You do not have an account for this study. Please contact your study lead.";
const AUTH_DISABLED_USER = "Your account is registered, but is not enabled. Please contact Helpdesk to enable your account.";
const NO_ROLE_MESSAGE = "Your account does not detail your user role. Please contact your study lead.";

export const AUTH_FRIENDLY_MESSAGES = {
  "Your account does not exist.": NO_ACCOUNT_FOUND_FOR_STUDY,
  "Your account is not enabled.": AUTH_DISABLED_USER,
  "Your account is not enabled for login": NO_ROLE_MESSAGE
};