import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Rnd } from 'react-rnd';
import { InsightService } from "src/utils/network/InsightService";
import { removeInsight, setFocusedInsight, setInsightCoordinates, toggleInsight } from "src/store/actions/insight.action";
import { ReactComponent as Spinner } from "src/images/insight_spinner.svg";


const Insight = ({ details, coordinates }) => {
    const dispatch = useDispatch();
    const iframeRef = useRef(null);
    const insightService = new InsightService();
    const [loadingClass, setLoadingClass] = useState('');
    const [movingClass, setMovingClass] = useState('');
    const [insightContent, setInsightContent] = useState('');
    const focusedInsight = useSelector((state) => state.toolbeltInsights.focusedInsight);

    const insightCoords = coordinates ? coordinates : {
      x: 10,
      y: 60,
      height: 400,
      width: 400
    };
    async function getInsightContent() {
      setLoadingClass('is-loading');
      const content = await insightService.getInsight(details);
      setLoadingClass('');
      setInsightContent(content);
    }

    useEffect(() => {
      getInsightContent()
    }, []);

    const reloadInsight = () => {
      iframeRef.current.contentWindow.location.reload();
    }

    const handleInsightClick = () => {
      dispatch(setFocusedInsight(details.id));
    }

    return (
      <Rnd
        className={focusedInsight === details.id ? 'focused-insight' : ''}
        size={{
          width: insightCoords.width,
          height: insightCoords.height
        }}
        position={{
          x: insightCoords.x,
          y: insightCoords.y
        }}
        enableResizing={{
          bottom: false,
          bottomLeft: false,
          bottomRight: true,
          left: false,
          right: false,
          top: false,
          topLeft: false,
          topRight: false
        }}
        style={{
          zIndex: 99999
        }}
        onDrag={(e, d) => {
          setMovingClass('is-moving');
        }}
        onDragStop={(e, d) => {
          setMovingClass('');
          dispatch(setInsightCoordinates(details.id, {
            ...insightCoords,
            x: d.x,
            y: d.y
          }))
        }}
        onResize={(e, direction, ref, delta, position) => {
          dispatch(setInsightCoordinates(details.id, {
            width: ref.offsetWidth,
            height: ref.offsetHeight,
            ...position,
          }));
        }}
      >
        <div className="insight">
          <div className="insight-header cursor" onClick={handleInsightClick}>
            <span>{details.title}</span>
            <button
              type="button"
              className="insight-button insight-close"
              tabIndex="-1"
              title="Close"
              onClick={() => {
                dispatch(toggleInsight(details.id))
              }}>
              <i className="zmdi zmdi-close zmdi-hc-fw"></i>
            </button>
            <button
              type="button"
              className="insight-button insight-reload"
              tabIndex='-1'
              title="Reload"
              onClick={reloadInsight}>
                <i className="zmdi zmdi-refresh zmdi-hc-fw"></i>
            </button>
            <button
              type="button"
              className="insight-button insight-remove"
              tabIndex='-1'
              title="Remove"
              onClick={() => {
                dispatch(removeInsight(details.id));
                dispatch(toggleInsight(details.id));
              }}>
                <i className="zmdi zmdi-delete zmdi-hc-fw"></i>
            </button>
          </div>
          <div className="insight-content no-cursor">
            <div className={`insight-spinner ${loadingClass} ${movingClass}`}>
              <Spinner viewBox="0 0 16 16" />
            </div>
            <section
              className="insight-section"
              data-id={details.id}
              data-insight-definition={
                JSON.stringify({
                  ...details,
                  plugin_name: details.gallery
                })}>
              <iframe
                name={`insight.${details.id}`}
                title={details.id}
                className="insight-frame"
                ref={iframeRef}
                srcDoc={insightContent}>
              </iframe>
            </section>
          </div>
        </div>
      </Rnd>
    );
}

Insight.propTypes = {
  details: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    params: PropTypes.object,
    gallery: PropTypes.string
  }),
  coordinates: PropTypes.object,
}

export default Insight;
