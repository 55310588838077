import Storage from "./storage/Storage";

class Geolocation {
  constructor() {
    this.storageKey = "location_collected";
    this.stashedLocations = [];
  }

  getGeolocation() {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject);
    });
  }

  stashGeolocation(location) {
    this.stashedLocations.push(location);
    Storage.set(this.storageKey, true);
  }
}
export const GeolocationInstance = new Geolocation();
