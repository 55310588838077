import React from "react";
import { useSelector } from "react-redux";

const TimeoutWarning = () => {
  const show = useSelector((state) => state.heartbeatReducer.showWarning);
  const showClass = show ? "show-warning" : "";
  return (
    <div
      id="ice-user-inactivity-warning"
      className={`inactivity-warning ${showClass}`}>
      <i className="zmdi zmdi-hc-fw zmdi-time zmdi-hc-3x"></i>
      <main>
        <div>
          {"You're about to be automatically logged out due to inactivity."}
        </div>
        <div>Move the mouse or press a key to stay logged in.</div>
      </main>
    </div>
  );
};

export default TimeoutWarning;
