import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getHost } from "src/utils/utils";
import { logout } from "src/store/actions/auth.action";

export const TARGET_AUTH = "target/auth";
export const TARGET_HOME = "target/home";
// For Redirects to other pages or dedicated apps.
export const TARGET_OTHER = "target/other";

/**
 * This hook will return the processed target
 * based on factors such as authentication, query params etc
 */
export const useTargetProcessor = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.authReducer);
  const appConfig = useSelector((state) => state.appConfig);

  const [target, setTarget] = useState(TARGET_AUTH);
  const [otherUrl, setOtherUrl] = useState(undefined);

  const [decideTarget, setDecideTarget] = useState(false);

  /**
   * This effect will provide decideTarget the true value if
   * everything was loaded (logged out, logged in).
   */
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);

    const authLoading = auth.status.loading;
    const logoutRoute = queryParams.get("action") === "logout";

    const loading = authLoading || logoutRoute;
    setDecideTarget(!loading);
  }, [auth.status.loading]);

  /**
   * Logout Effect
   *
   * This effect is prioritized because if the user
   * is on the &action=logout route, we must do nothing else
   * than log him out and redirect to home.
   */
  useEffect(() => {
    if (decideTarget) return;

    const queryParams = new URLSearchParams(window.location.search);
    const postLogout = () => {
      let route = `/launch/index.html`;
      if (queryParams.has("next")) {
        const next = queryParams.get("next");
        route = `${route}?next=${encodeURIComponent(next)}`;
      }
      setTarget(TARGET_OTHER);
      setOtherUrl(route);
    };

    if (queryParams.get("action") === "logout") {
      dispatch(logout())
        .then(() => postLogout())
        .catch(() => postLogout());
    }
  }, [window.location.search, decideTarget, auth.status.loggedIn]);

  useEffect(() => {
    if (!decideTarget) return;
    const queryParams = new URLSearchParams(window.location.search);

    if (!auth.status.loggedIn) {
      // setTarget(TARGET_HOME); // ENABLE THIS FOR DEV
      setTarget(TARGET_AUTH);
    } else {
      if (process.env.NODE_ENV === "development") {
        setTarget(TARGET_HOME);
        return;
      }
      /** POST Auth Redirect Priorities:
       * 1. Query Param (?next=/esourcetraining/)
       * 2. Last app
       * 3. Single App
       *
       * If Auto Login, then Show home.
       */
      if (window.location.pathname === '/launch/') {
        setTarget(TARGET_HOME);
        return;
      }
      const shouldRedirectToSingleApp = appConfig.toolbelt.apps.length === 1;
      const shouldRedirectToLastApp = auth.lastAppUrl;
      const shouldRedirectToQueryParam = queryParams.get("next") !== null;
      const shouldRedirectToHome =
        !shouldRedirectToSingleApp &&
        !shouldRedirectToLastApp &&
        !shouldRedirectToQueryParam;

      if (shouldRedirectToQueryParam) {
        const queryHash = window.location.hash;
        setTarget(TARGET_OTHER);
        setOtherUrl(`${getHost()}${queryParams.get("next")}${queryHash}`);
        return;
      }

      if (shouldRedirectToLastApp) {
        setTarget(TARGET_OTHER);
        setOtherUrl(`${getHost() + auth.lastAppUrl}`);
        return;
      }

      if (shouldRedirectToSingleApp) {
        setTarget(TARGET_OTHER);
        setOtherUrl(`${getHost() + appConfig.toolbelt.apps[0].url}`);
        return;
      }

      if (shouldRedirectToHome) {
        setTarget(TARGET_HOME);
        return;
      } else {
        setTarget(TARGET_OTHER);
        setOtherUrl(`${getHost}?action=logout`);
        return;
      }
    }
  }, [
    decideTarget,
    auth.status.loggedIn,
    auth.loginRedirect.autoLoggedIn,
    auth.lastAppUrl,
    appConfig.toolbelt.apps
  ]);

  return { target, otherUrl };
};
