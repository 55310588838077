import React from "react";

const AuthFooter = () => {
  return (
    <footer>
      <div className="launch-container text-center">
        <a
          className="footer-logo"
          href="http://www.cmedresearch.com/"
          target="_blank"
          rel="noopener noreferrer"
          alt="Cmed">
        </a>
        <p className="copy">
          encapsia<sup>&reg;</sup> software is the property of{" "}
          <a
            href="http://www.cmedresearch.com"
            target="_blank"
            rel="noopener noreferrer">
            Cmed Technology Ltd
          </a>
          , all rights reserved. &copy; 2016-{new Date().getFullYear()} Cmed
          Group Ltd
        </p>
      </div>
    </footer>
  );
};

export default AuthFooter;
