import { getHost } from "src/utils/utils";
import { Service } from "./Service";
import Http from "./Http";
import { COLOR_BY_CATEGORY } from "src/constants/appColors";

const appsPluginName = {
  'analytics': 'analytics_addon',
}

export class AppService extends Service {
  async getInstalledPluginApps() {
    try {
      const response = await Http.get({
        url: `/v1/views/pluginsmanager/plugins?having_tags=["webapp","tutor-enabled"]`,
        headers: {
          ...this.getAuthHeader(),
          ...this.getAcceptTypeJsonHeader(),
          ...this.setSkipTutorHeader()
        }
      });
      return response.data;
    } catch {
      return [];
    }
  }

  async getCurrentAppTags(name) {
    const currentPluginName = (appsPluginName[name])? appsPluginName[name] : name;
    try {
      const response = await Http.get({
        url: `/v1/views/launch/get_plugin_tags/${currentPluginName}`,
        headers: {
          ...this.getAuthHeader(),
          ...this.getAcceptTypeJsonHeader(),
          ...this.setSkipTutorHeader()
        }
      });
      return response.data[0].get_plugin_tags;
    } catch {
      return undefined;
    }
  }

  async getAppInfo(name) {
    try {
      const response = await Http.get({
        url: `/${name}/manifest.json`,
        headers: {
          ...this.getAuthHeader(),
          ...this.getAcceptTypeJsonHeader(),
          ...this.setSkipTutorHeader()
        }
      });
      return response.data;
    } catch {
      return undefined;
    }
  }

  async getApps() {
    try {
      let apps = [];
      const pluginApps = await this.getInstalledPluginApps();
      for (let plugin of pluginApps) {
        if (plugin.manifest.webapp) {
          let webapp = plugin.manifest.webapp;
          apps.push({
            ...webapp,
            description: plugin.description,
            url: webapp.redirect_to + "/",
            iconUrl: `${webapp.logo}`
          });
        } else {
          let appInfo = await this.getAppInfo(plugin.name);
          if (appInfo) {
            apps.push({
              ...appInfo,
              url: appInfo.url + "/",
              iconUrl: `/${plugin.name}/${appInfo.logo}`,
              color: COLOR_BY_CATEGORY[appInfo.category]
            });
          }
        }
      }
      return apps;
    } catch {
      return [];
    }
  }
}
