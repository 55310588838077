export const getHost = () => {
  if (window.location.origin) {
    return (process.env.NODE_ENV === "development" && process.env.REACT_APP_ICEHOST)
      ? process.env.REACT_APP_ICEHOST
      : window.location.protocol + "//" + window.location.hostname;
  } else {
    return `${window.location.protocol}//${window.location.host}`;
  }
};

export const getCurrentAppName = () => {
  let currentApp = window.location.pathname.split('/')[1];
  return currentApp.replace(/\//g, "");
};

export const listToObject = (itemList) => {
  return itemList.reduce((itemObj, item) => {
    itemObj[item.key] = item.value;
    return itemObj;
  }, {});
};

export const shouldRunStateMaintainer = () => {
  const queryParams = new URLSearchParams(window.location.search);
  return queryParams.get("action") !== "logout";
};

