import React from "react";
import Proptypes from "prop-types";
import { openInsight } from 'src/store/actions/insight.action';
import { useDispatch } from "react-redux";

const InsightApp = ({ app, children }) => {
  const dispatch = useDispatch();
  return  (
    <a
    title={`${app.description}`}
    onClick={() => dispatch(openInsight(app.id))}
    >
      {children}
    </a>
  )
}


InsightApp.propTypes = {
  app: Proptypes.object,
  children: Proptypes.element
};

export default InsightApp;