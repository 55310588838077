import { useEffect, useState } from "react";
import { getHost } from "src/utils/utils";
import Http from "src/utils/network/Http";
import { DEFAULT_EXTERNAL_LINK } from "src/constants/auth"

export const useHelpdeskConfig = () => {
  const [externalContactLink, setExternalContactLink] = useState(DEFAULT_EXTERNAL_LINK);

  useEffect(() => {
    const fetch = async () => {
      try {
        const response = await Http.get({
          url: "/",
          headers: { Accept: "application/json" }
        });

        const externalContactLink = response.data.result.helpdesk_contacts?.external_contact_link
        if (externalContactLink) {
          setExternalContactLink(externalContactLink);
        }
      } catch (error) {
        console.error(error);
        return;
      }
    };
    fetch();
  }, []);
  return [externalContactLink];
};
