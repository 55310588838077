import Storage from './Storage';

export const loadState = (key) => {
  try {
    const serializedState = Storage.get(key);
    if (serializedState) {
      return JSON.parse(serializedState);
    }
    return undefined;
  } catch (err) {
    console.log(err);
    return undefined;
  }
}

export const saveState = (key, state) => {
  try {
    const serializedState = JSON.stringify(state);
    Storage.set(key, serializedState);
  } catch (err) {
    console.log(err);
  }
}