export const useAnimate = () => {
  const animate = (
    time = 450,
    beforeTimeout = () => {},
    afterTimeout = () => {}
  ) => {
    beforeTimeout();
    setTimeout(() => {
      afterTimeout();
    }, time);
  };

  return [animate];
};
