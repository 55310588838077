import React from 'react';
import { useSelector } from 'react-redux';
import CollapsibleMenu from './CollapsibleMenu';


const ViewAllMode = () => {
  const apps = useSelector((state) => state.appConfig.toolbelt.apps);
  const insightsAccess = useSelector((state) => state.toolbeltInsights.insightsAccess);
  const insights = useSelector((state) => state.toolbeltInsights.insights);

  const sortByTitle = (itemList) => {
    return [...itemList].sort((a,b) => a.title > b.title ? 1 : -1);
  }

  return (
    <div  className='view-all-mode'>
      <CollapsibleMenu title={"Apps"} apps={sortByTitle(apps)} isCollapsed={false} />
      {insightsAccess && <CollapsibleMenu title={"Insights"} apps={sortByTitle(Object.values(insights))} isCollapsed={true} />}
    </div>
  )
}


export default ViewAllMode;