import React from 'react';
import PropTypes from 'prop-types';

import RecentlyUsedMode from './RecentlyUsedMode';
import ViewAllMode from './ViewAllMode';
import { TOOLBELT_RECENTLY_USED_MODE, TOOLBELT_VIEW_ALL_MODE } from 'src/constants/tools';

const AppsSection = ({ activeMode }) => {
  const appsClasses = [
    "apps-section",
    "encapsia-scroller",
    "encapsia-scroll-scheme-dark",
    "encapsia-scroller-launch"
  ];

  return (
    <div className={appsClasses.join(" ")}>
      {activeMode === TOOLBELT_RECENTLY_USED_MODE && <RecentlyUsedMode />}
      {activeMode === TOOLBELT_VIEW_ALL_MODE && <ViewAllMode />}
    </div>
  )
}

AppsSection.propTypes = {
  activeMode: PropTypes.string
}

export default AppsSection;