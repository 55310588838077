import React, { useState, useEffect, useCallback, useRef } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import {
  setAppConfig,
  SET_APP_CONFIG_TOOLBELT_TOOL_PANEL_ANIMATION_PROGRESS,
  SET_APP_CONFIG_TOOLBELT_DRAG_AND_DROP_PAYLOAD,
  removeToolMessageInQueue
} from "src/store/actions/app-config.action";
import { getHost } from "src/utils/utils";
import tools, {
  TOOLBELT_TOOL_TUTOR,
  TOOLBELT_TOOL_TAGS
} from "src/constants/tools";

const ToolbeltToolPanel = ({ tracker, onRefSet = (ref) => { } }) => {
  const dispatch = useDispatch();
  const toolbelt = useSelector((state) => state.appConfig.toolbelt);
  const dragAndDropPayload = toolbelt.dragAndDropPayload;
  const toolMessagesQueue = toolbelt.toolMessagesQueue;

  const activeTool = toolbelt.activeTool;
  const toolPanelAnimationInProgress = toolbelt.toolPanelAnimationInProgress;

  const [animationState, setAnimationState] = useState(
    ToolbeltToolPanelAnimationState.CLOSED
  );

  const panelClasses = ["toolbelt-tool-panel-wrapper", animationState];

  const tagsIframe = useRef(null);
  const tutorIframe = useRef(null);

  const animate = useCallback(
    (fromAnimationState, toAnimationState, time = 300) => {
      setAnimationState(fromAnimationState);
      dispatch(
        setAppConfig(
          SET_APP_CONFIG_TOOLBELT_TOOL_PANEL_ANIMATION_PROGRESS,
          true
        )
      );
      setTimeout(() => {
        setAnimationState(toAnimationState);
        dispatch(
          setAppConfig(
            SET_APP_CONFIG_TOOLBELT_TOOL_PANEL_ANIMATION_PROGRESS,
            false
          )
        );
      }, time);
    },
    [dispatch]
  );

  const getContentWindow = (callback, forIframe) => {
    if (forIframe && forIframe.current && forIframe.current.contentWindow) {
      const contentWindow = forIframe.current.contentWindow;

      if (contentWindow.document.readyState === "complete") {
        forIframe.current.contentWindow.onload = () => {
          callback(forIframe.current.contentWindow);
        };
        callback(forIframe.current.contentWindow);
        return;
      }
    }

    setTimeout(() => getContentWindow(callback, forIframe), 100);
  };
  /**
   * Side Effect that will send the drag and drop
   * payload if found in the store.
   */
  useEffect(() => {
    if (tagsIframe.current !== null && dragAndDropPayload !== undefined) {
      getContentWindow((contentWindow) => {
        contentWindow.postMessage(dragAndDropPayload, "*");
      }, tagsIframe);
      dispatch(
        setAppConfig(SET_APP_CONFIG_TOOLBELT_DRAG_AND_DROP_PAYLOAD, undefined)
      );
    }
  }, [dragAndDropPayload, dispatch]);

  /**
   * Side Effect that will send the messages from the queue. 
   */
  useEffect(() => {
    for (const message of toolMessagesQueue) {
      const tool = tools.find(t => t.id === message.tool);
      const toolRef = getRef(tool);
      if (toolRef) {
        getContentWindow((contentWindow) => {
          contentWindow.postMessage(message.message, "*");
          dispatch(removeToolMessageInQueue(message.id));
        }, toolRef);
      }
    }
  }, [toolMessagesQueue, dispatch]);

  useEffect(() => {
    if (toolPanelAnimationInProgress) return;
    const activeToolIsSet = activeTool !== undefined;
    if (
      !activeToolIsSet &&
      animationState === ToolbeltToolPanelAnimationState.OPEN
    ) {
      animate(
        ToolbeltToolPanelAnimationState.CLOSING,
        ToolbeltToolPanelAnimationState.CLOSED,
        450
      );
      const iframeRef = getRef(activeTool);
      getContentWindow((contentWindow) => {
        contentWindow.postMessage({ type: "closed" }, "*");
      }, iframeRef);
    }
    if (
      activeToolIsSet &&
      animationState === ToolbeltToolPanelAnimationState.CLOSED
    ) {
      animate(
        ToolbeltToolPanelAnimationState.OPENING,
        ToolbeltToolPanelAnimationState.OPEN,
        300
      );
      const iframeRef = getRef(activeTool);
      getContentWindow((contentWindow) => {
        contentWindow.postMessage({ type: "opened" }, "*");
      }, iframeRef);
    }
  }, [activeTool, animationState, animate, toolPanelAnimationInProgress]);

  const iframeShown = (currentTool) => {
    if (
      activeTool !== undefined &&
      animationState === ToolbeltToolPanelAnimationState.OPEN
    ) {
      return currentTool.key === activeTool.key;
    } else {
      return false;
    }
  };

  const getRef = (currentTool) => {
    if (!currentTool) return undefined;
    switch (currentTool.key) {
      case TOOLBELT_TOOL_TAGS:
        return tagsIframe;
      case TOOLBELT_TOOL_TUTOR:
        return tutorIframe;
      default:
        return undefined;
    }
  };

  return (
    <div
      ref={(ref) => onRefSet(ref)}
      className={panelClasses.join(" ")}
      data-animation={ToolbeltToolPanelAnimationKey[animationState]}>
      {toolbelt.tools
        .filter((t) => t.url)
        .map((t) => (
          <iframe
            id={`icetoolbelt-iframe-${t.title.toLowerCase()}`}
            ref={getRef(t)}
            key={t.key}
            title={t.title}
            style={{
              display: iframeShown(t) ? "block" : "none"
            }}
            src={`${getHost() + t.url}`}
            onLoad={(e) => {
              if (process.env.NODE_ENV === "development") return;
              tracker.addListenersOnFrame(e.target);
              e.target.contentWindow.addEventListener("beforeunload", () => {
                tracker.removeListenersOnFrame(e.target);
              });
            }}></iframe>
        ))}
    </div>
  );
};

ToolbeltToolPanel.propTypes = {
  tracker: PropTypes.object,
  onRefSet: PropTypes.func
}

const ToolbeltToolPanelAnimationState = {
  OPENING: "opening",
  OPEN: "open",
  CLOSED: "closed",
  CLOSING: "closing"
};

const ToolbeltToolPanelAnimationKey = {
  [ToolbeltToolPanelAnimationState.OPENING]: "open-bullet",
  [ToolbeltToolPanelAnimationState.CLOSING]: "close-bullet"
};

export default ToolbeltToolPanel;
