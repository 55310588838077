import { saveState } from "src/utils/storage/storePersistance";
import { InsightService } from "src/utils/network/InsightService";

export const SET_ALL_INSIGHTS = "insights/set-all-insights";
export const SET_TOOLBELT_PINNED_INSIGHTS = "insights/set-pinned-insights";
export const SET_TOOLBELT_INSIGHT_ACTIVE = "insights/set-active-insight";
export const SET_EXCEED_LIMIT_WARNING = "insights/set-exceed-limit-warning";
export const SET_ALREADY_PINNED_INSIGHT_WARNING = "insights/set-already-pinned-insight-warning";
export const SET_INSIGHT_COORDINATES = "insights/set-insight-coordinates";
export const SET_INSIGHTS_ACCESS = "insights/set-no-insights-access";
export const SET_FOCUSED_INSIGHT = "insights/set-focused-insight";
export const SET_ALREADY_OPENED_INSIGHT_WARNING = "insight/set-already-opened-insight-warning";

const setInsights = (type, payload) => ({ type, payload });
const setExceedLimit = (payload) => ({ type: SET_EXCEED_LIMIT_WARNING, payload });
const setAlreadyPinned = (payload) => ({ type: SET_ALREADY_PINNED_INSIGHT_WARNING, payload });
const setAlreadyOpened = (payload) => ({ type: SET_ALREADY_OPENED_INSIGHT_WARNING, payload });
const setInsightsAccess = (payload) => ({ type: SET_INSIGHTS_ACCESS, payload });

export const setFocusedInsight = (payload) => ({ type: SET_FOCUSED_INSIGHT, payload });

export const setInsightCoordinates = (insightId, coordinates) => {
  return (dispatch, getState) => {
    saveState('insightsCoordinates', {
      ...getState().toolbeltInsights.insightsCoordinates,
      [insightId]: coordinates
    })
    dispatch({
      type: SET_INSIGHT_COORDINATES,
      payload: {
        [insightId]: coordinates
      }
    });
  }
}

export const initInsights = () => {
  return (dispatch, getState) => {
    const insightsApp = getState().appConfig.toolbelt.apps.find(app => app.title === 'Insights');
    if (insightsApp) {
      dispatch(setInsightsAccess(true));
      dispatch(fetchInsightsDetails(insightsApp));
    } else {
      dispatch(setInsightsAccess(false));
    }
  }
}

export const fetchInsightsDetails = (insightsApp) => {
  return async (dispatch) => {
    const insightService = new InsightService();
    const pinnedInsights = await insightService.getPinnedInsights();
    const insightsList = await insightService.getInsights();
    let insights = {};
    for (let insight of insightsList) {
      insight.iconUrl = insightsApp.iconUrl;
      insight.color = insightsApp.color;
      insights[insight.id] = insight;
    }
    const filteredPinnedInsights = pinnedInsights.filter(name => insights[name] !== undefined);
    if (filteredPinnedInsights.length !== pinnedInsights.length) {
      await insightService.postInsights(filteredPinnedInsights);
    }
    dispatch(setInsights(SET_ALL_INSIGHTS, insights));
    dispatch(setInsights(SET_TOOLBELT_PINNED_INSIGHTS, filteredPinnedInsights));
    window.postMessage({
      type: 'launch.pinned-insights-loaded',
      data: filteredPinnedInsights
    }, "*");
  }
}

export const toggleInsight = (insightId) => {
  return (dispatch, getState) => {
    const activeInsights = getState().toolbeltInsights.activeInsights;
    if (activeInsights.includes(insightId)) {
      const newActiveInsights = [...activeInsights];
      newActiveInsights.splice(activeInsights.indexOf(insightId), 1);
      dispatch(setInsights(SET_TOOLBELT_INSIGHT_ACTIVE, newActiveInsights));
    } else {
      dispatch(setInsights(SET_TOOLBELT_INSIGHT_ACTIVE, [...activeInsights, insightId]));
    }
  }
}

export const removeInsight = (insightId) => {
  return async (dispatch, getState) => {
    const insightService = new InsightService();
    const pinnedInsights = getState().toolbeltInsights.pinnedInsights;
    const newInsights = pinnedInsights.filter(insight => insight !== insightId);
    const response = await insightService.postInsights(newInsights);
    if(response['status'] === 200) {
      dispatch(setInsights(SET_TOOLBELT_PINNED_INSIGHTS, newInsights));
      window.postMessage({
        type: 'launch.insight-unpinned',
        data: insightId
      }, "*");
    }
  }
}

export const addInsight = (insightId) => {
  return async (dispatch, getState) => {
    const insightService = new InsightService();
    const pinnedInsights = getState().toolbeltInsights.pinnedInsights;

    if (pinnedInsights.indexOf(insightId) !== -1) {
      dispatch(setAlreadyPinned(true));
      setTimeout(() => {
        dispatch(setAlreadyPinned(false));
      }, 2000);
      return;
    }

    if (pinnedInsights.length >= 10) {
      dispatch(setExceedLimit(true));
      setTimeout(() => {
        dispatch(setExceedLimit(false))
      }, 2000);
      return;
    }

    const response = await insightService.postInsights([...pinnedInsights, insightId]);
    if(response['status'] === 200 || response['status'] === 201) {
      dispatch(setInsights(SET_TOOLBELT_PINNED_INSIGHTS, [...pinnedInsights, insightId]));
      window.postMessage({
        type: 'launch.insight-pinned',
        data: insightId
      }, "*");
    }
  }
}

export const isPinnedInsight = (insightId) => {
  return (dispatch, getState) => {
    const pinnedInsights = getState().toolbeltInsights.pinnedInsights;
    return pinnedInsights.indexOf(insightId) !== -1;
  }
}

export const openInsight = (insightId) => {
  return (dispatch, getState) => {
    const activeInsights = getState().toolbeltInsights.activeInsights;
    if (activeInsights.includes(insightId)) {
      dispatch(setAlreadyOpened(true));
      setTimeout(() => {
        dispatch(setAlreadyOpened(false));
      }, 2000);
    } else {
      dispatch(setInsights(SET_TOOLBELT_INSIGHT_ACTIVE, [...activeInsights, insightId]));
    }
  }
}