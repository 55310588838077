import { Service } from "./Service";
import Http from "./Http";

export class ToolService extends Service {
  async toolIsInstalled(tool) {
    try {
      const response = await Http.get({
        url: `${this.baseUrl}${tool.url}`,
        headers: { ...this.getAuthHeader() },
      });

      return response.status === 200;
    } catch (error) {
      return false;
    }
  }
}
