import React, { useState } from "react";

import AuthBox from "../auth/AuthBox";
import AuthFooter from "../auth/AuthFooter";

const AuthLayout = () => {
  const [backgroundImageClasses] = useState([
    "large-image-background",
    "image-loaded"
  ]);

  return (
    <div className="auth-wrapper launch-default-body encapsia-default-body">
      <main className="launch-login-center">
        <div className={backgroundImageClasses.join(" ")}></div>
        <AuthBox />
      </main>
      <AuthFooter />
    </div>
  );
};

export default AuthLayout;
