const DEFAULT_EVENTS = [
  "click",
  "mousemove",
  "keydown",
  "scroll",
  "wheel",
  "drag"
];
const THRESHOLD = 100;
const LOADING_FRAMES_TIME = 5000;

const activityTracker = () => {
  let lastActivityTime = -Infinity;
  let iframes = [];

  const getSecondsAgo = () => {
    return (Date.now() - parseInt(lastActivityTime)) / 1000;
  };

  const updateActivityTime = () => {
    if (Date.now() - lastActivityTime > THRESHOLD) {
      lastActivityTime = Date.now();
    }
  };

  const listenToActivity = (contentWindow) => {
    DEFAULT_EVENTS.forEach((eventName) => {
      contentWindow.addEventListener(eventName, updateActivityTime);
    });
  };

  const removeListeners = (contentWindow) => {
    DEFAULT_EVENTS.forEach((eventName) => {
      contentWindow.removeEventListener(eventName, updateActivityTime);
    });
  };

  const addListenersOnFrame = (frame) => {
    let isSameOrigin;
    try {
      isSameOrigin = Boolean(frame.contentDocument);
    } catch (err) {
      isSameOrigin = false;
    }
    if (isSameOrigin) {
      iframes.push(frame);
      listenToActivity(frame.contentWindow);
    }
  };

  const removeListenersOnFrame = (currentIframe) => {
    if (currentIframe) {
      removeListeners(currentIframe.contentWindow);
      const frameIndex = iframes.findIndex((f) => f === currentIframe);
      if (frameIndex !== -1) {
        iframes.splice(frameIndex, 1);
      }
    }
  };

  const addListenersOnAvailableFrames = () => {
    document
      .querySelectorAll("iframe")
      .forEach((iframe) => addListenersOnFrame(iframe));
  };

  const removeListenersOnAvailableFrames = () => {
    iframes.forEach((iframe) => removeListenersOnFrame(iframe));
  };

  const start = () => {
    updateActivityTime();
    listenToActivity(window);
    setTimeout(addListenersOnAvailableFrames, LOADING_FRAMES_TIME);
  };

  const stop = () => {
    removeListeners(window);
    removeListenersOnAvailableFrames();
  };

  return {
    getSecondsAgo,
    start,
    stop,
    addListenersOnFrame,
    removeListenersOnFrame
  };
};

export const tracker = activityTracker();
