import { useEffect, useState, useCallback } from "react";
import { useAnimate } from "./useAnimate";
import { useDispatch, useSelector } from "react-redux";
import { BasicFadeAnimationState } from "src/constants/animations";
import {
  SET_APP_CONFIG_TOOLBELT_APP_LAUNCHER_ANIMATION_PROGRESS,
  setAppConfig
} from "src/store/actions/app-config.action";

export const useLauncherAnimation = () => {
  const dispatch = useDispatch();
  const [animate] = useAnimate();

  const toolbelt = useSelector((state) => state.appConfig.toolbelt);
  const appLauncherClosed = toolbelt.appLauncherClosed;
  const appLauncherAnimationInProgress =
    toolbelt.appLauncherAnimationInProgress;
  const [animationState, setAnimationState] = useState(
    appLauncherClosed
      ? BasicFadeAnimationState.CLOSED
      : BasicFadeAnimationState.OPEN
  );
  const setAppLauncherAnimationProgress = useCallback(
    (inProgress) => {
      dispatch(
        setAppConfig(
          SET_APP_CONFIG_TOOLBELT_APP_LAUNCHER_ANIMATION_PROGRESS,
          inProgress
        )
      );
    },
    [dispatch]
  );

  useEffect(() => {
    if (appLauncherAnimationInProgress) return;
    if (
      !appLauncherClosed &&
      animationState === BasicFadeAnimationState.CLOSED
    ) {
      animate(
        300,
        () => {
          setAppLauncherAnimationProgress(true);
          setAnimationState(BasicFadeAnimationState.OPENING);
        },
        () => {
          setAnimationState(BasicFadeAnimationState.OPEN);
          setAppLauncherAnimationProgress(false);
        }
      );
    }
    if (appLauncherClosed && animationState === BasicFadeAnimationState.OPEN) {
      animate(
        300,
        () => {
          setAppLauncherAnimationProgress(true);
          setAnimationState(BasicFadeAnimationState.CLOSING);
        },
        () => {
          setAnimationState(BasicFadeAnimationState.CLOSED);
          setAppLauncherAnimationProgress(false);
        }
      );
    }
  }, [
    appLauncherAnimationInProgress,
    animationState,
    appLauncherClosed,
    animate,
    setAppLauncherAnimationProgress
  ]);

  return [animationState];
};
