import { useTargetProcessor, TARGET_OTHER } from "./useTargetProcessor";
import { useEffect } from "react";

export const useCustomRouter = () => {
  // Initial Target should be the Auth Page.
  const { target, otherUrl } = useTargetProcessor();

  useEffect(() => {
    if (target === TARGET_OTHER && otherUrl !== undefined) {
      window.location.assign(otherUrl);
    }
  }, [otherUrl, target]);

  return { target };
};
