import logger from "redux-logger";
import freeze from "redux-freeze";
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import reducers from "./reducers";

const configureStore = initialState => {
  const middlewares = [thunk];
  if (process.env.REACT_APP_REDUX_LOGGER === "true") middlewares.push(logger);
  if (process.env.NODE_ENV === "development") middlewares.push(freeze);

  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const enhancer = composeEnhancers(applyMiddleware(...middlewares));
  return createStore(reducers, initialState, enhancer);
};

const store = configureStore({});

export default store;
