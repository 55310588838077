import { Service } from "./Service";
import UserStorage from "../storage/UserStorage";
import Http from "./Http";

export class InsightService extends Service {

  async getTask(namespace, taskId) {
    const response = await Http.get({
      url: `/v1/tasks/${namespace}/${taskId}`,
      headers: {
        ...this.getAuthHeader(),
        ...this.getAcceptTypeJsonHeader(),
        ...this.setSkipTutorHeader()
      }
    });
    return response.data;
  }

  async pollTask(namespace, taskId) {
    const executePoll = async (resolve, reject) => {
      const response = await this.getTask(namespace, taskId);
      if (response && response.result.status === 'finished') {
        return resolve(response.result.result);
      }
      if (response.result.status === 'failed') {
        return reject(response.result.status);
      }

      setTimeout(executePoll, 500, resolve, reject);
    }

    return new Promise(executePoll);
  }

  async getInsight(insightDetails) {
    try {
      const response = await Http.get({
        url: `/${insightDetails.gallery}/${insightDetails.root_html}`,
        headers: {
          ...this.getAuthHeader(),
          ...this.setSkipTutorHeader()
        }
      });
      const content = response.data.split("./").join(`/${insightDetails.gallery}/${insightDetails.id}/`);
      return content;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async getInsightsPlugins() {
    try {
      const response = await Http.get({
        url: `/v1/views/pluginsmanager/plugins?having_tags=["insights_gallery", "insights-gallery"]`,
        headers: {
          ...this.getAuthHeader(),
          ...this.setSkipTutorHeader()
        }
      });
      return response.data;
    } catch(error) {
      console.log(error);
      return [];
    }
  }

  async getInsightsDefinitions(plugin) {
    try {
      const response = await Http.post({
        url: `/v1/tasks/${plugin.name}/gallery.get_insight_definitions`,
        headers: {
          ...this.getAuthHeader(),
          ...this.setSkipTutorHeader(),
          ...this.postNoContentTypeJsonHeader()
        },
        data: null
      });
      const taskId = response.data.result['task_id'];
      const insightsGallery = await this.pollTask(plugin.name, taskId);
      return insightsGallery;
    } catch(error) {
      console.log(error);
      return [];
    }
  }

  filterPluginsByPermissions(plugins) {
    const capabilities = UserStorage.getCapabilities().split(",");
    const isSuperuser = capabilities.find((cap) => cap === "superuser") !== undefined;
    if (isSuperuser) {
      return plugins;
    } else {
      return plugins.filter(plugin => {
        return capabilities.indexOf(`iceweb${plugin.name}`) !== -1;
      });
    }
  }


  async getInsights() {
    try {
      const insights = [];
      const plugins = await this.getInsightsPlugins();
      const filteredPlugins = this.filterPluginsByPermissions(plugins);
      for(let plugin of filteredPlugins) {
        if (plugin.manifest.gallery) {
          for (let insight of plugin.manifest.gallery) {
            if (insight.pinnable) {
              insight["gallery"] = plugin.name;
              insights.push(insight);
            }
          }
        } else {
          let insightsGallery = await this.getInsightsDefinitions(plugin);
          let filteredInsights = insightsGallery.reduce((result, insight) => {
            if (insight.pinnable) {
              insight['gallery'] = plugin.name;
              result.push(insight);
            }
            return result;
          }, []);
          insights.push(...filteredInsights);
        }
      }
      return insights;
    } catch(error) {
      console.log(error);
      return [];
    }
  }

  async getPinnedInsights() {
    try {
      const response = await Http.get({
        url: `${this.baseUrl}/v1/userpreferences/${encodeURIComponent(UserStorage.getEmail())}/launch_insights`,
        headers: { ...this.getAuthHeader() },
      });
      return response.data.result.value;
    } catch (error) {
      console.log(error);
      return [];
    }
  }

  async postInsights(insights) {
    try {
      const postData = { 'value': insights };
      const response = await Http.put({
        url: `${this.baseUrl}/v1/userpreferences/${encodeURIComponent(UserStorage.getEmail())}/launch_insights`,
        headers: {
          ...this.getAuthHeader(),
          ...this.setContentTypeJsonHeader()
         },
        data: JSON.stringify(postData)
      });
      return response;
    } catch(error) {
      console.log(error);
      return error;
    }
  }
}
