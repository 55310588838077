export const OPTIMAL_BROWSERS = [
  {
    name: "Chrome",
    engine: "Blink"
  },
  {
    name: "Chrome",
    engine: "WebKit"
  },
  {
    name: "Microsoft Edge",
    engine: "Blink"
  },
  {
    name: "Microsoft Edge",
    engine: "EdgeHTML"
  }
];
