import { useState } from "react";
import { useToolToggler } from "./useToolToggler";
import {
  setAppConfig,
  SET_APP_CONFIG_TOOLBELT_DRAG_AND_DROP_PAYLOAD
} from "src/store/actions/app-config.action";
import { useDispatch } from "react-redux";

export const useToolDragAndDrop = ({ tool }) => {
  const dispatch = useDispatch();
  const { didClickTool, activeTool } = useToolToggler({ tool });
  const [dragOverClass, setDragOverClass] = useState("");

  const isCurrentToolActive = () => {
    if (!activeTool) return false;
    return activeTool.key === tool.key;
  };
  const onDragOver = (event) => {
    if (!tool.dragAndDropEnabled) return;
    event.preventDefault();
    setDragOverClass("drag-over");
  };
  const onDragLeave = () => {
    if (!tool.dragAndDropEnabled) return;
    setDragOverClass("");
  };
  const onDrop = (event) => {
    if (!tool.dragAndDropEnabled) return;
    event.preventDefault();

    const payload = {
      type: "drop",
      payload: JSON.parse(event.dataTransfer.getData("text"))
    };

    if (!isCurrentToolActive()) {
      didClickTool();
    }
    dispatch(
      setAppConfig(SET_APP_CONFIG_TOOLBELT_DRAG_AND_DROP_PAYLOAD, payload)
    );
    setDragOverClass("");
  };

  return [dragOverClass, onDragOver, onDragLeave, onDrop];
};
