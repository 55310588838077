import { LANGUAGE_ENGLISH } from "src/constants/languages";
import toolbeltTools, {
  TOOLBELT_TOOL_LAUNCH,
  TOOLBELT_TOOL_TUTOR
} from "src/constants/tools";
import { setStrings } from "./lang.action";
import { ToolService } from "src/utils/network/ToolService";
import UserStorage from "src/utils/storage/UserStorage";
import { AuthService } from "src/utils/network/AuthService";
import { AppService } from "src/utils/network/AppService";
import {
  setLogin,
  SIGN_IN_SUCCESS,
  logout,
  AUTH_LAST_APP,
  SIGN_IN_LOADING
} from "./auth.action";
import { OPERATION_MODE_FULL } from "src/constants/mode";
import { initInsights, openInsight } from "./insight.action";
import { ActivityService } from "src/utils/network/ActivityService";
import { getCurrentAppName } from "src/utils/utils";

export const SET_APP_CONFIG_LANGUAGE = "app-config/set/language";
export const SET_APP_CONFIG_OPERATION_MODE = "app-config/set/operation-mode";
export const SET_APP_CONFIG_TOOLBELT_APPS = "app-config/set/toolbelt/apps";
export const SET_RECENTLY_USED_APPS = "app-config/set/recently-used-apps";
export const SET_APP_CONFIG_TOOLBELT_TOOLS = "app-config/set/toolbelt/tools";
export const SET_APP_CONFIG_TOOLBELT_ACTIVE_TOOL =
  "app-config/set/toolbelt/active-tool";
export const SET_APP_CONFIG_TOOLBELT_TOOL_PANEL_ANIMATION_PROGRESS =
  "app-config/set/toolbelt/tool-panel-animation-in-progress";
export const SET_APP_CONFIG_TOOLBELT_DRAG_AND_DROP_PAYLOAD =
  "app-config/set/toolbelt/drag-and-drop-payload";
export const SET_APP_CONFIG_TOOLBELT_APP_LAUNCHER_CLOSED =
  "app-config/set/toolbelt/app-launcher-closed";
export const SET_APP_CONFIG_TOOLBELT_APP_LAUNCHER_ANIMATION_PROGRESS =
  "app-config/set/toolbelt/app-launcher-animation-in-progress";
export const SET_APP_CONFIG_TOOLBELT_TOOL_MESSAGES_IN_QUEUE =
  "app-config/set/toolbelt/tool-messages-in-queue";
export const REMOVE_APP_CONFIG_TOOLBELT_TOOL_MESSAGES_IN_QUEUE =
  "app-config/remove/toolbelt/tool-messages-in-queue";
export const SET_APP_CONFIG_TOOLBELT_TOOL_MESSAGES_QUEUE =
  "app-config/set/toolbelt/tool-messages-queue";
export const SET_APP_CONFIG_HOME_ANIMATION_PROGRESS =
  "app-config/set/home-animation-in-progress";
export const SET_APP_CONFIG_HOME_ELEMENTS_SHOWN =
  "app-config/set/home-elements-shown";
export const SET_APP_CONFIG_HOME_ELEMENTS_ANIMATE_ON_LOAD =
  "app-config/set/home-elements-animate-on-load";
export const SET_SEARCH_VALUE = "app-config/set/search-value";
export const SET_LAUNCH_LAST_MODE = "app-config/set/launch-last-mode";

const toolExcluded = (tool, pluginTags) => {
  const excluded = window.IceToolbelt_excludeTools;
  const regex = new RegExp(/tutor-enabled/g);

  // tutor icon check
  if (tool.id == "tutor") {
    return pluginTags && pluginTags.match(regex)
      ? false
      : true;
  }
  if (excluded && excluded.includes(tool.id)) return true;

  return false;
};

export const setAppConfig = (type, payload) => ({ type, payload });

export const setAppLanguage = (language = LANGUAGE_ENGLISH) => {
  return (dispatch) => {
    dispatch(setStrings(language));
    dispatch(setAppConfig(SET_APP_CONFIG_LANGUAGE, language));
  };
};

export const setAppToolbeltApps = (toolbeltApps, basedOnCaps) => {
  return (dispatch) => {
    const caps = basedOnCaps.split(",");
    const isSuperuser = caps.find((cap) => cap === "superuser") !== undefined;
    if (isSuperuser) {
      dispatch(setAppConfig(SET_APP_CONFIG_TOOLBELT_APPS, toolbeltApps));
      return;
    }
    const appsPermitted = toolbeltApps.filter((app) => {
      return caps.find((cap) => cap === app.access_capability) !== undefined;
    });

    dispatch(setAppConfig(SET_APP_CONFIG_TOOLBELT_APPS, appsPermitted));
  };
};

export const setAppToolbeltTools = () => {
  return async (dispatch, getState) => {
    if (process.env.NODE_ENV === "development") {
      dispatch(setAppConfig(SET_APP_CONFIG_TOOLBELT_TOOLS, toolbeltTools));
      return;
    }

    const toolService = new ToolService();
    const appService = new AppService();
    const currentApp = getCurrentAppName();
    const pluginTags = await appService.getCurrentAppTags(currentApp);
    const operationMode = getState().appConfig.operationMode;
    let toolsPermitted = [];

    for (const tool of toolbeltTools.filter((t) => t.url !== undefined)) {
      // Particular case: Tutor must be hidden on Launch
      if (
        tool.key === TOOLBELT_TOOL_TUTOR &&
        operationMode === OPERATION_MODE_FULL
      ) {
        continue;
      }
      const isInstalled = await toolService.toolIsInstalled(tool);
      if (isInstalled) {
        toolsPermitted.push({
          ...tool,
          isEnabled: !toolExcluded(tool, pluginTags)
        });
      }
    }
    // Add static tools such as the Launcher
    toolsPermitted.push(
      toolbeltTools.find((t) => t.key === TOOLBELT_TOOL_LAUNCH)
    );

    dispatch(setAppConfig(SET_APP_CONFIG_TOOLBELT_TOOLS, toolsPermitted));
  };
};

export const setToolMessageInQueue = (message = { tool: "", message: "" }) => {
  return {
    type: SET_APP_CONFIG_TOOLBELT_TOOL_MESSAGES_IN_QUEUE,
    payload: message
  };
};

export const removeToolMessageInQueue = (id = "") => {
  return {
    type: REMOVE_APP_CONFIG_TOOLBELT_TOOL_MESSAGES_IN_QUEUE,
    payload: id
  };
};

export const setToolMessagesQueue = (queue = []) => {
  return { type: SET_APP_CONFIG_TOOLBELT_TOOL_MESSAGES_QUEUE, payload: queue };
};

export const setLastApp = async (url) => {
  const authService = new AuthService();
  await authService.setLastApp(url);
};

export const logAccessApp = async (url) => {
  const activityService = new ActivityService();
  const appName = url.slice(1, -1);
  const formattedAppName = appName.substring(0, 1).toUpperCase() + appName.substring(1);
  await activityService.logActivity(`User accesses ${formattedAppName}`);
};

const updateUsedApps = (recentlyUsedApps, lastApp) => {
  const index = recentlyUsedApps.indexOf(lastApp);
  let newApps = [...recentlyUsedApps];
  if (index !== -1) {
    newApps.splice(index, 1);
    newApps = [lastApp, ...newApps];
  } else {
    if (recentlyUsedApps.length === 3) {
      newApps.splice(recentlyUsedApps.length - 1, 1);
      newApps = [lastApp, ...newApps];
    } else {
      newApps = [lastApp, ...recentlyUsedApps];
    }
  }
  return newApps;
};

export const stateMaintainer = () => {
  return async (dispatch, getState) => {
    const token = UserStorage.getToken();
    if (!token) return;

    const authService = new AuthService();
    const appService = new AppService();
    dispatch(setLogin(SIGN_IN_LOADING, true));
    try {
      await authService.getWhoAmI(token);
      const shortTrialName = await authService.getShortTrialName();
      authService.populateShortTrialName(shortTrialName);
      dispatch(setLogin(SIGN_IN_SUCCESS));
      const lastMode = await authService.getLaunchLastMode();
      const apps = await appService.getApps();
      const recentlyUsedApps = await authService.getRecentlyUsedApps();
      if (lastMode) {
        dispatch(setAppConfig(SET_LAUNCH_LAST_MODE, lastMode));
      }
      dispatch(setAppConfig(SET_RECENTLY_USED_APPS, recentlyUsedApps));
      dispatch(setAppToolbeltApps(apps, UserStorage.getCapabilities()));
      dispatch(setAppToolbeltTools());
      dispatch(initInsights());
      const lastAppUrl = await authService.getLastApp();
      const accessibleApps = getState().appConfig.toolbelt.apps.map(app => app.url);
      // need to extract the app name from path because analytics has pathname = analytics/labs
      const currentApp = window.location.pathname.substring(0, window.location.pathname.lastIndexOf('/') + 1);

      // need to check if current path is one of accessibleApps and set it as last app.
      // this can happen when navigate using url or when open an app in a new tab from Launch
      if (currentApp !== lastAppUrl && accessibleApps.indexOf(currentApp) !== -1) {
        await setLastApp(currentApp);
        await logAccessApp(currentApp);
        const newRecentlyUsedApps = updateUsedApps(recentlyUsedApps, currentApp);
        await authService.updateRecentlyUsedApps(newRecentlyUsedApps);
        dispatch(setAppConfig(SET_RECENTLY_USED_APPS, newRecentlyUsedApps));
        dispatch(setLogin(AUTH_LAST_APP, currentApp));
      } else if (lastAppUrl) {
        await logAccessApp(lastAppUrl);
        const newRecentlyUsedApps = updateUsedApps(recentlyUsedApps, lastAppUrl);
        await authService.updateRecentlyUsedApps(newRecentlyUsedApps);
        dispatch(setAppConfig(SET_RECENTLY_USED_APPS, newRecentlyUsedApps));
        dispatch(setLogin(AUTH_LAST_APP, lastAppUrl));
      }
      dispatch(setLogin(SIGN_IN_LOADING, false));
    } catch {
      dispatch(logout());
    }
  };
};

export const setSearchValue = (value) => {
  return (dispatch) => {
    dispatch(setAppConfig(SET_SEARCH_VALUE, value));
  };
};

export const openSearchedApp = (searchValue) => {
  return (dispatch, getState) => {
    const apps = getState().appConfig.toolbelt.apps;
    const insights = getState().toolbeltInsights.insights;
    const filteredApps = searchValue ? apps.filter(app => app.title.toLowerCase().includes(searchValue)) : apps;
    const filteredInsights = searchValue ? Object.values(insights).filter(app => app.title.toLowerCase().includes(searchValue)) : Object.values(insights);
    if (filteredApps.length + filteredInsights.length === 1) {
      if (filteredInsights.length) {
        dispatch(openInsight(filteredInsights[0].id));
      }
      if (filteredApps.length) {
        window.location.assign(filteredApps[0].url);
      }
    }
  };
};

export const setLaunchLastMode = (lastMode) => {
  return async (dispatch) => {
    const authService = new AuthService();
    await authService.updateLaunchLastMode(lastMode);
    dispatch(setAppConfig(SET_LAUNCH_LAST_MODE, lastMode));
  };
};