import { combineReducers } from "redux";

import appConfig from "./app-config.reducer";
import authReducer from "./auth.reducer";
import configReducer from "./config.reducer";
import langReducer from "./lang.reducer";
import heartbeatReducer from "./heartbeat.reducer";
import toolbeltInsights from './insights.reducer';

export default combineReducers({
  appConfig,
  authReducer,
  configReducer,
  langReducer,
  heartbeatReducer,
  toolbeltInsights
});
