import {
  SET_DISPLAY_WARNING,
  SET_USER_INACTIVE
} from "../actions/heartbeat.action";

const initialState = {
  showWarning: false,
  userInactive: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_DISPLAY_WARNING:
      return {
        ...state,
        showWarning: action.payload
      };
    case SET_USER_INACTIVE:
      return {
        ...state,
        userInactive: true
      };
    default:
      return state;
  }
};
