import Http from "src/utils/network/Http";
import { getHost } from "src/utils/utils";
import Storage from "src/utils/storage/Storage";
import { WHOAMI_POLLING_TIME } from "src/constants/timers";

export const SET_DISPLAY_WARNING = "set-display-warning";
export const SET_USER_INACTIVE = "set-user-inactive";

export const setDisplayWarning = (displayed) => ({
  type: SET_DISPLAY_WARNING,
  payload: displayed
});

export const setUserInactive = () => ({
  type: SET_USER_INACTIVE
});

export const pollWhoAmI = () => {
  return (dispatch) => {
    const getWhoAmI = () => {
      Http.get({
        url: `${getHost()}/v1/whoami`,
        headers: {
          Authorization: `Bearer ${Storage.get("token")}`,
          Accept: "application/json"
        }
      }).catch((err) => {
        if (
          err &&
          err.response &&
          err.response.status &&
          err.response.status === 401
        ) {
          dispatch(setUserInactive());
        } else {
          console.warn("Heartbeat checker received a non-401 failure", err);
        }
      });
    };

    setInterval(getWhoAmI, WHOAMI_POLLING_TIME);
  };
};
