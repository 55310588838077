import React from "react";

import { AuthLayout, HomeLayout } from "src/components/layout/";
import { useCustomRouter } from "./hooks/router/useCustomRouter";
import { TARGET_AUTH, TARGET_HOME } from "./hooks/router/useTargetProcessor";

function App() {
  const { target } = useCustomRouter();

  return (
    <>
      {target === TARGET_AUTH && <AuthLayout />}
      {target === TARGET_HOME && <HomeLayout />}
    </>
  );
}

export default App;
