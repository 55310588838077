import React from "react";
import { useSelector } from "react-redux";
import Insight from "../Insight";

const InsightContainer = () => {
    const activeInsights = useSelector((state) => state.toolbeltInsights.activeInsights);
    const insightsDetails = useSelector(state => state.toolbeltInsights.insights);
    const insightsCoordinates = useSelector(state => state.toolbeltInsights.insightsCoordinates);
    return (
        <div className="insights-container">
            {activeInsights.map( name => (
                <Insight
                    key={name}
                    details={insightsDetails[name]}
                    coordinates={insightsCoordinates[name]} />
            ))}
        </div>
    );
}

export default InsightContainer;
