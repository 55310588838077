import { useEffect, useState } from "react";
import { getHost } from "src/utils/utils";
import Http from "src/utils/network/Http";
import Storage from "src/utils/storage/Storage";
import { DEFAULT_SESSION_LIFESPAN } from "src/constants/timers";

export const useSessionLifespan = () => {
  const [sessionLifespan, setSessionLifespan] = useState(
    DEFAULT_SESSION_LIFESPAN
  );
  const [loadingSessionLifespan, setLoadingSessionLifespan] = useState(true);

  useEffect(() => {
    const fetch = async () => {
      const response = await Http.get({
        url: `${getHost()}/v1/config/session_lifespan`,
        headers: { Authorization: `Bearer ${Storage.get("token")}` }
      });

      const serverSessionLifespan = response.data.result.session_lifespan;
      setSessionLifespan(serverSessionLifespan);
      setLoadingSessionLifespan(false);
    };
    fetch();
  }, []);
  return [sessionLifespan, loadingSessionLifespan];
};
