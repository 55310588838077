import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { AUTH_PROVIDERS } from "src/constants/auth";
import { login } from "src/store/actions/auth.action";

const AuthProviders = () => {
  const config = useSelector((state) => state.configReducer);
  const strings = useSelector((state) => state.langReducer.strings);

  const dispatch = useDispatch();

  const providerButton = (providerName, shouldShownSignInText = false) => {
    const buttonClasses = [`${providerName}-provider`];
    const providerConstant = AUTH_PROVIDERS.find(
      (pc) => pc.name === providerName
    );
    return providerConstant ? (
      <button
        data-provider={providerName}
        className={buttonClasses}
        key={providerName}
        onClick={() => dispatch(login(providerConstant))}>
        <span>
          {shouldShownSignInText
            ? strings.LOGIN.SIGN_IN
            : providerConstant.provider}
        </span>
        <i className="zmdi zmdi-hc-fw zmdi-arrow-right"></i>
      </button>
    ) : null;
  };

  return (
    <section className="providers">
      {config.providers.map((p) =>
        providerButton(p, config.providers.length === 1)
      )}
    </section>
  );
};

export default AuthProviders;
