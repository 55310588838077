import browserinfo from "browser-info";
import { Service } from "./Service";
import Http from "./Http";
import { GeolocationInstance } from "../Geolocation";
import { AuthService } from "./AuthService";

export class ActivityService extends Service {

  async logActivity(activity) {
    const data = {
      what: activity,
      how: this.getDeviceInfo(),
      where: this.getAvailablePosition()
    };

    try {
      const res = await Http.post({
        url: `${this.baseUrl}/v1/activities`,
        headers: {
          ...this.getAuthHeader(),
          ...this.setContentTypeJsonHeader() },
        data: data
      });
      return res.status === 200;
    } catch (error) {
      return false;
    }
  }

  async uploadDeviceInformation() {
    const data = {
      device: this.getDeviceInfo()
    };
    try {
      const authService = new AuthService();
      const res = await authService.putWhoAmI(data);
      return res === 200;
    } catch {
      return false;
    }
  }

  getDeviceInfo() {
    const browserInfo = browserinfo();
    return `Browser ${browserInfo.name} ${browserInfo.version} on ${browserInfo.os}`;
  }

  getAvailablePosition() {
    const availablePosition =
      GeolocationInstance.stashedLocations[
        GeolocationInstance.stashedLocations.length - 1
      ];
    return availablePosition ? `${availablePosition.coords.latitude},${availablePosition.coords.longitude}` : "";
  }
}
