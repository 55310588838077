import {
  SIGN_IN_LOADING,
  SIGN_OUT_LOADING,
  SIGN_IN_SUCCESS,
  SIGN_OUT_SUCCESS,
  SIGN_IN_FAILURE,
  SIGN_OUT_FAILURE,
  AUTH_LAST_APP,
  REDIRECT_AFTER_LOGIN
} from "../actions/auth.action";

const initialState = {
  lastAppUrl: undefined,
  status: {
    loading: false,
    loggedIn: false,
    error: undefined
  },
  loginRedirect: {
    url: undefined,
    shouldRedirect: false,
    autoLoggedIn: false
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SIGN_IN_LOADING:
    case SIGN_OUT_LOADING:
      return {
        ...state,
        status: { ...state.status, loading: action.payload }
      };
    case SIGN_IN_SUCCESS:
      return {
        ...state,
        status: {
          ...state.status,
          loading: false,
          loggedIn: true
        }
      };
    case SIGN_OUT_SUCCESS:
      return {
        ...state,
        status: {
          ...state.status,
          loading: false,
          loggedIn: false
        }
      };
    case SIGN_IN_FAILURE:
    case SIGN_OUT_FAILURE:
      return {
        ...state,
        status: { ...state.status, loading: false, error: action.payload }
      };
    case AUTH_LAST_APP:
      return {
        ...state,
        lastAppUrl: action.payload
      };
    case REDIRECT_AFTER_LOGIN:
      return {
        ...state,
        loginRedirect: {
          ...state.loginRedirect,
          shouldRedirect: action.payload
        }
      };
    default:
      return state;
  }
};
