import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import {createRoot} from "react-dom/client";
import { Provider } from "react-redux";

import App from "./App";
import * as serviceWorker from "./serviceWorker";
import store from "./store";

import "src/scss/index.scss";
import "encapsiadesign/css/encapsia.css";
import "encapsiadesign/css/encapsia-identify-theme.css";

import {
  setAppConfig,
  SET_APP_CONFIG_OPERATION_MODE
} from "src/store/actions/app-config.action";
import { getProviders } from "src/store/actions/config.action";

import { stateMaintainer } from "./store/actions/app-config.action";
import { OPERATION_MODE_FULL } from "./constants/mode";
import { shouldRunStateMaintainer } from "./utils/utils";

const host =
  (process.env.NODE_ENV === "development" && process.env.REACT_APP_ICEHOST)
    ? process.env.REACT_APP_ICEHOST
    : window.location.protocol + "//" + window.location.hostname;

store.dispatch(getProviders(host));
if (shouldRunStateMaintainer()) {
  store.dispatch(stateMaintainer());
}
store.dispatch(
  setAppConfig(SET_APP_CONFIG_OPERATION_MODE, OPERATION_MODE_FULL)
);

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

serviceWorker.unregister();
