import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSearchValue, openSearchedApp } from 'src/store/actions/app-config.action';

const SearchSection = () => {
  const dispatch = useDispatch();
  const searchValue = useSelector((state) => state.appConfig.toolbelt.searchValue);
  const appLauncherAnimationInProgress = useSelector((state) => state.appConfig.toolbelt.appLauncherAnimationInProgress);
  const appLauncherClosed = useSelector((state) => state.appConfig.toolbelt.appLauncherClosed);
  const inputRef = useRef(null);

  useEffect(() => {
    inputRef.current.focus();
  });

  useEffect(() => {
    if (appLauncherClosed && !appLauncherAnimationInProgress) {
      dispatch(setSearchValue(""));
    }
  }, [appLauncherClosed, appLauncherAnimationInProgress]);

  const onSearchChange = event => {
    dispatch(setSearchValue(event.target.value));
  }

  const onKeyPress = event => {
    if (event.key === 'Enter') {
      dispatch(openSearchedApp(searchValue));
    }
  }

  return (
    <div className="search-section">
      <div className="input-group">
        <span className="input-group-text">
          <i className="zmdi zmdi-search" />
        </span>
        <input
          ref={inputRef}
          type="text"
          className="search-input"
          placeholder="Type to begin search..."
          value={searchValue}
          onChange={onSearchChange}
          onKeyPress={onKeyPress} />
      </div>
    </div>
  )
}

export default SearchSection;