import React from "react";
import { useSelector } from "react-redux";
import { TOOL_INSIGHT } from "src/constants/tools";

import ToolbeltNavigationItem from "./ToolbeltNavigationItem";
import ToolbeltInsightButton from './ToolbeltInsightButton';

const ToolbeltNavigation = () => {
  const toolbelt = useSelector((state) => state.appConfig.toolbelt);
  const pinnedInsights = useSelector((state) => state.toolbeltInsights.pinnedInsights);

  return (
    <div className="tool-buttons">
      {pinnedInsights.map((insightId) => (
        <ToolbeltInsightButton key={insightId} tool={{
          ...TOOL_INSIGHT,
          customId: TOOL_INSIGHT.customId + insightId,
          key: insightId
        }} />
      ))}
      {toolbelt.tools.map((t) => (
        t.isEnabled && <ToolbeltNavigationItem key={t.key} tool={t} />
      ))}
    </div>
  );
};

export default ToolbeltNavigation;
