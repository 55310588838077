import { LANGUAGE_ENGLISH } from "src/constants/languages";
import {
  SET_APP_CONFIG_LANGUAGE,
  SET_APP_CONFIG_TOOLBELT_ACTIVE_TOOL,
  SET_APP_CONFIG_TOOLBELT_TOOL_PANEL_ANIMATION_PROGRESS,
  SET_APP_CONFIG_TOOLBELT_APP_LAUNCHER_ANIMATION_PROGRESS,
  SET_APP_CONFIG_TOOLBELT_APP_LAUNCHER_CLOSED,
  SET_APP_CONFIG_TOOLBELT_APPS,
  SET_RECENTLY_USED_APPS,
  SET_APP_CONFIG_TOOLBELT_TOOLS,
  SET_APP_CONFIG_HOME_ANIMATION_PROGRESS,
  SET_APP_CONFIG_HOME_ELEMENTS_SHOWN,
  SET_APP_CONFIG_HOME_ELEMENTS_ANIMATE_ON_LOAD,
  SET_APP_CONFIG_OPERATION_MODE,
  SET_APP_CONFIG_TOOLBELT_DRAG_AND_DROP_PAYLOAD,
  SET_SEARCH_VALUE,
  SET_LAUNCH_LAST_MODE,
  SET_APP_CONFIG_TOOLBELT_TOOL_MESSAGES_IN_QUEUE,
  SET_APP_CONFIG_TOOLBELT_TOOL_MESSAGES_QUEUE,
  REMOVE_APP_CONFIG_TOOLBELT_TOOL_MESSAGES_IN_QUEUE
} from "../actions/app-config.action";
import { OPERATION_MODE_FULL } from "src/constants/mode";

import toolbeltTools from "src/constants/tools";
import { TOOLBELT_VIEW_ALL_MODE } from "src/constants/tools";

const initialState = {
  language: LANGUAGE_ENGLISH,
  operationMode: OPERATION_MODE_FULL,
  toolbelt: {
    tools: toolbeltTools,
    toolMessagesQueue: [],
    apps: [],
    recentlyUsedApps: [],
    lastMode: TOOLBELT_VIEW_ALL_MODE,
    searchValue: "",
    activeTool: undefined,
    toolPanelAnimationInProgress: false,
    appLauncherClosed: false,
    appLauncherAnimationInProgress: false,
    dragAndDropPayload: undefined
  },
  homeAnimations: {
    homeElementsShown: true,
    homeAnimationInProgress: false,
    homeElementsShouldAnimateOnLoad: true
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_APP_CONFIG_LANGUAGE:
      return { ...state, language: action.payload };
    case SET_APP_CONFIG_OPERATION_MODE:
      return { ...state, operationMode: action.payload };
    case SET_APP_CONFIG_TOOLBELT_APPS:
      return {
        ...state,
        toolbelt: { ...state.toolbelt, apps: action.payload }
      };
    case SET_RECENTLY_USED_APPS:
      return {
        ...state,
        toolbelt: {
          ...state.toolbelt,
          recentlyUsedApps: action.payload
        }
      }
    case SET_APP_CONFIG_TOOLBELT_TOOLS:
      return {
        ...state,
        toolbelt: { ...state.toolbelt, tools: action.payload }
      };
    case SET_APP_CONFIG_TOOLBELT_TOOL_MESSAGES_IN_QUEUE:
      return {
        ...state,
        toolbelt: { ...state.toolbelt, toolMessagesQueue: [...state.toolbelt.toolMessagesQueue, { ...action.payload, id: `${Date.now()}${Math.random() * 10000}` }] }
      }
    case REMOVE_APP_CONFIG_TOOLBELT_TOOL_MESSAGES_IN_QUEUE:
      return {
        ...state,
        toolbelt: { ...state.toolbelt, toolMessagesQueue: [...state.toolbelt.toolMessagesQueue.filter(tm => tm.id !== action.payload)] }
      }
    case SET_APP_CONFIG_TOOLBELT_TOOL_MESSAGES_QUEUE:
      return {
        ...state,
        toolbelt: { ...state.toolbelt, toolMessagesQueue: [...action.payload] }
      }
    case SET_APP_CONFIG_TOOLBELT_ACTIVE_TOOL:
      return {
        ...state,
        toolbelt: { ...state.toolbelt, activeTool: action.payload }
      };
    case SET_APP_CONFIG_HOME_ANIMATION_PROGRESS:
      return {
        ...state,
        homeAnimations: {
          ...state.homeAnimations,
          homeAnimationInProgress: action.payload
        }
      };
    case SET_APP_CONFIG_HOME_ELEMENTS_ANIMATE_ON_LOAD:
      return {
        ...state,
        homeAnimations: {
          ...state.homeAnimations,
          homeElementsShouldAnimateOnLoad: action.payload
        }
      };
    case SET_APP_CONFIG_HOME_ELEMENTS_SHOWN:
      return {
        ...state,
        homeAnimations: {
          ...state.homeAnimations,
          homeElementsShown: action.payload
        }
      };
    case SET_APP_CONFIG_TOOLBELT_TOOL_PANEL_ANIMATION_PROGRESS:
      return {
        ...state,
        toolbelt: {
          ...state.toolbelt,
          toolPanelAnimationInProgress: action.payload
        }
      };
    case SET_APP_CONFIG_TOOLBELT_APP_LAUNCHER_ANIMATION_PROGRESS:
      return {
        ...state,
        toolbelt: {
          ...state.toolbelt,
          appLauncherAnimationInProgress: action.payload
        }
      };
    case SET_APP_CONFIG_TOOLBELT_APP_LAUNCHER_CLOSED:
      return {
        ...state,
        toolbelt: { ...state.toolbelt, appLauncherClosed: action.payload }
      };
    case SET_APP_CONFIG_TOOLBELT_DRAG_AND_DROP_PAYLOAD:
      return {
        ...state,
        toolbelt: { ...state.toolbelt, dragAndDropPayload: action.payload }
      };
    case SET_SEARCH_VALUE:
      return {
        ...state,
        toolbelt: {
          ...state.toolbelt,
          searchValue: action.payload
        }
      }
    case SET_LAUNCH_LAST_MODE:
      return {
        ...state,
        toolbelt: {
          ...state.toolbelt,
          lastMode: action.payload
        }
      }
    default:
      return state;
  }
};
