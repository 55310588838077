import { Service } from "./Service";
import Http from "./Http";
import { getHost } from "../utils";

export class ConfigService extends Service {
  async getGoogleTrackingId() {
    return Http.get({
      url: `/v1/config/google_analytics`,
      headers: { ...this.getAcceptTypeJsonHeader(), ...this.getAuthHeader() }
    });
  }

  async getInstrumentationConfig() {
    try {
      const res = await Http.get({
        url: `/v1/config/instrumentation`,
        headers: { ...this.getAcceptTypeJsonHeader(), ...this.getAuthHeader() }
      });
      return res.data.result.instrumentation;
    } catch {
      return undefined;
    }
  }

}
