import React from "react";
import PropTypes from "prop-types";

const Banner = ({
  show = false,
  type = null,
  title = null,
  message = null,
  dismiss
}) => {
  const alertClass = type ? `alert alert-${type}` : "";
  const errorBannerClasses = ["banner-box", alertClass];
  if (show) errorBannerClasses.push("show");

  return (
    <section className={errorBannerClasses.join(" ")}>
      {dismiss && <button type="button" className="dismiss" title="Dismiss warning" onClick={dismiss}>
                    <i className="zmdi zmdi-hc-fw zmdi-close dismiss-icon" />
                  </button>}
      {title && <span className="message-title">{title}</span>}
      {title && message && <br />}
      {message && <span className="message-text">{message}</span>}
    </section>
  );
};

Banner.propTypes = {
  show: PropTypes.bool,
  type: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.string,
  dismiss: PropTypes.func
};

export default Banner;
