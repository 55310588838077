import { useWrapperClickDetector } from "./useWrapperClickDetector";
import { useSelector, useDispatch } from "react-redux";

import {
  setAppConfig,
  SET_APP_CONFIG_TOOLBELT_APP_LAUNCHER_CLOSED
} from "src/store/actions/app-config.action";
import { useCallback, useEffect, useState } from "react";

export const useToolbeltComponentsClickDetector = () => {
  const dispatch = useDispatch();
  const toolbelt = useSelector((state) => state.appConfig.toolbelt);
  const appLauncherClosed = toolbelt.appLauncherClosed;

  const { registerRef } = useWrapperClickDetector();

  const [refs, setRefs] = useState([]);
  const [whitelistedRefs, setWhitelistedRefs] = useState([]);

  useEffect(() => {
    registerRef({ refs: whitelistedRefs, whitelist: true });
    registerRef({ refs, whitelist: false });
  }, [registerRef, refs, whitelistedRefs]);

  const processRefs = ({ key, switcher, newRef }) => {
    const existingRef = refs.find((r) => r.key === key);
    if (existingRef) {
      if (existingRef.switcher !== switcher) {
        let allRefs = refs.filter((r) => r.key !== existingRef.key);
        allRefs.push(newRef);
        setRefs(allRefs);
      }
    } else {
      setRefs([...refs, newRef]);
    }
  };

  const getRef = ({ key, ref, switcher, options }) => ({
    key,
    ref,
    switcher,
    options
  });

  const registerAppLauncherClickDetector = useCallback(
    (ref) => {
      if (ref === null) return;
      const key = "TOOLBELT_APP_LAUNCHER";
      const switcher = appLauncherClosed;
      const options = {
        onOutsideClick: () => {
          if (!appLauncherClosed) {
            dispatch(
              setAppConfig(SET_APP_CONFIG_TOOLBELT_APP_LAUNCHER_CLOSED, true)
            );
          }
        }
      };
      const newRef = getRef({ key, ref, switcher, options });
      processRefs({ key, switcher, newRef });
    },
    [appLauncherClosed, refs]
  );

  const registerAppFooterClickDetector = useCallback(
    (ref) => {
      if (ref === null) return;
      const key = "TOOLBELT_APP_LAUNCHER";
      const existingRef = whitelistedRefs.find((r) => r.key === key);
      if (existingRef === undefined) {
        setWhitelistedRefs([
          ...whitelistedRefs,
          {
            key,
            ref
          }
        ]);
      }
    },
    [whitelistedRefs]
  );

  return {
    registerAppLauncherClickDetector,
    registerAppFooterClickDetector
  };
};
