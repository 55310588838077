import React from "react";
import PropTypes from "prop-types";

const InsightWarning = ({ show, message }) => {
  const showClass = show ? "show-warning" : "";
  return (
    <div
      className={`insight-warning ${showClass}`}>
      <i className="zmdi zmdi-hc-fw zmdi-alert-circle-o zmdi-hc-2x"></i>
      <main>
        <div>
          {message}
        </div>
      </main>
    </div>
  );
};

InsightWarning.propTypes = {
  show: PropTypes.bool,
  message: PropTypes.string
}

export default InsightWarning;
