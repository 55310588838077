import React from "react";
import { useDispatch } from "react-redux";
import { logout } from "src/store/actions/auth.action";
import UserStorage from "src/utils/storage/UserStorage";

import { useHomeAnimation } from "src/hooks/useHomeAnimation";
import { useOperationMode } from "src/hooks/useOperationMode";
import { LOGOUT_ROUTE } from "src/constants/routes";

const UserBar = () => {
  const dispatch = useDispatch();
  const [animationState] = useHomeAnimation();
  const [operationModeClasses] = useOperationMode();
  const userBarClasses = ["user-bar", ...operationModeClasses, animationState];

  return (
    <div className={userBarClasses.join(" ")}>
      <div className="name">{UserStorage.getName()}</div>
      <a
        href={LOGOUT_ROUTE.path}
        className="logout"
        title="Sign out"
        onClick={() => dispatch(logout())}>
        <i className="zmdi zmdi-power zmdi-hc-fw"></i>
      </a>
    </div>
  );
};

export default UserBar;
