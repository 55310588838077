import React, { useRef } from "react";
import PropTypes from "prop-types";
import { useToolToggler } from "src/hooks/useToolToggler";
import { useToolDragAndDrop } from "src/hooks/useToolDragAndDrop";
import { TOOLBELT_TOOL_LAUNCH } from "src/constants/tools";
import { useHotkeys } from 'react-hotkeys-hook';

const ToolbeltNavigationItem = ({ tool }) => {
  const { didClickTool, getActiveClass, getDisabledClass } = useToolToggler({
    tool
  });
  const [dragOverClass, onDragOver, onDragLeave, onDrop] = useToolDragAndDrop({
    tool
  });
  const toolRef = useRef(null);

  useHotkeys('ctrl+enter', () => {
    if (tool.key === TOOLBELT_TOOL_LAUNCH) {
      toolRef.current.click();
    }
  });

  const toolClassNames = [
    "tool-button",
    getActiveClass(),
    getDisabledClass(),
    dragOverClass
  ];

  return (
    <div
      ref={toolRef}
      key={tool.key}
      id={tool.customId}
      className={toolClassNames.join(" ")}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
      onClick={didClickTool}>
      <a role="button" title={tool.title}>
        <i className={tool.iClass}>{tool.iContent}</i>
      </a>
    </div>
  );
};

ToolbeltNavigationItem.propTypes = {
  tool: PropTypes.object
};

export default ToolbeltNavigationItem;
