import React, { useState } from "react";
import Bowser from "bowser";

import Storage from "src/utils/storage/Storage";
import { Banner } from "src/components/reusable";
import { OPTIMAL_BROWSERS } from "src/constants/browsers";

const BrowserWarning = () => {
  const browserInfo = Bowser.getParser(window.navigator.userAgent);
  const [dismissWarning, setDismissWarning] = useState(Storage.get('dismiss_optimal_browser'));
  const isOptimalBrowser =
    OPTIMAL_BROWSERS.findIndex((b) => {
      return (
        b.name === browserInfo.getBrowserName() &&
        b.engine === browserInfo.getEngineName()
      );
    }) !== -1;
  // check if is Safari on ipad. Same check as on esource training.
  const isiPadWithDesktopWebsite = navigator.userAgent.toLowerCase().indexOf('macintosh') > 0 && 'ontouchend' in document;
  const isOptimal = isOptimalBrowser || isiPadWithDesktopWebsite;

  const onDismiss = () => {
    Storage.set('dismiss_optimal_browser', true);
    setDismissWarning(true);
  }

  return (
    <Banner
      show={!isOptimal && !dismissWarning}
      type="warning"
      message="We’ve noticed that you’re not using one of our optimal browsers. For the
      best encapsia experience please use the latest Google Chrome or
      Microsoft Edge version 79+."
      dismiss={onDismiss}
    />
  );
};

export default BrowserWarning;
