import { LANGUAGE_ENGLISH } from "src/constants/languages";

export const SET_LANG_STRINGS = "lang/set/strings";

/**
 * This function should be called by setAppLanguage from the app-config.action.js file.
 * Do not setStrings without setting the app language in config.
 */
export const setStrings = (language = LANGUAGE_ENGLISH) => ({
  type: SET_LANG_STRINGS,
  payload: language
});
