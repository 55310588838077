import React from "react";
import PropTypes from "prop-types";

const LoadingSpinner = ({ loading = false }) => {
  const componentClasses = ["loading-spinner"];
  if (loading) componentClasses.push("show");

  return (
    <div className={componentClasses.join(" ")}>
      <i className="zmdi zmdi-hc-5x zmdi-rotate-right zmdi-hc-spin"></i>
    </div>
  );
};

LoadingSpinner.propTypes = {
  loading: PropTypes.bool
};

export default LoadingSpinner;
