import storage from "src/constants/storage";

class Storage {
  static get(key) {
    const s = storage.find((s) => key in s);
    return s ? s.getItem(key) : undefined;
  }

  static getAllKeys() {
    const s = storage[0];
    return Object.keys(s);
  }

  static set(key, value) {
    storage.forEach((s) => {
      // it was agreed to store `null` as an empty string
      const processedValue = value === null ? "" : value;
      s.setItem(key, processedValue);
    });
  }

  static remove(key) {
    storage.forEach((s) => {
      s.removeItem(key);
    });
  }
}

export default Storage;
